/** @jsx jsx */
import "./EllipsisLoader.css";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";

function EllipsisLoader(props) {
  if (!props.visible) {
    return null;
  }

  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

EllipsisLoader.propTypes = {
  visible: PropTypes.bool.isRequired,
};

EllipsisLoader.defaultProps = {
  visible: true,
};

export default EllipsisLoader;
