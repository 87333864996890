import * as routeHelpers from "../../lib/routeHelpers";
import * as tournamentPlayers from "../../lib/api/tournamentPlayers";
import Card from "../Card";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import StyledButton from "../StyledButton";
import TextInput from "../TextInput";
import {
  getAgeToday,
  stringToDate,
  stringToFormattedDate,
} from "../../lib/dateUtil";
import { withRouter } from "react-router-dom";

const strings = {
  title: "Player Details",
};

const inputs = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  RATING: "usattRating",
  USATT_ID: "usattId",
  PAYMENT: "payment",
  USATT_PRODUCT: "usattProductCode",
  CHECKED_IN: "checkedIn",
};

const paymentOptions = [
  { value: "unpaid", label: "Unpaid" },
  { value: "cash", label: "Cash" },
  { value: "check", label: "Check" },
  { value: "paypal", label: "Paypal" },
];

const usattProductOptions = [
  { value: null, label: "None" },
  { value: 70, label: "1 Year Basic", price: 25 },
  { value: 53, label: "1 Year Pro", price: 75 },
  { value: 61, label: "Tournament Pass Adult", price: 50 },
  { value: 72, label: "Tournament Pass Junior", price: 20 },
  { value: 59, label: "Lifetime", price: 1300 },
];

const checkedInOptions = [
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

class TournamentPlayerScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    player: null,
    inputs: {
      [inputs.FIRST_NAME]: "",
      [inputs.LAST_NAME]: "",
      [inputs.RATING]: "",
      [inputs.USATT_ID]: "",
      [inputs.USATT_PRODUCT]: null,
      [inputs.CHECKED_IN]: false,
    },
    loading: false,
  };

  componentDidMount() {
    const tournamentId = this.props.match.params.id;
    const playerId = this.props.match.params.playerId;
    this.setState({ loading: true });
    tournamentPlayers
      .getPlayer(tournamentId, playerId)
      .then(this._updateStatePlayer)
      .then(() => this.setState({ loading: false }));
  }

  _updateStatePlayer = player => {
    this.setState({
      player,
      inputs: {
        [inputs.FIRST_NAME]: player.firstName || "",
        [inputs.LAST_NAME]: player.lastName || "",
        [inputs.RATING]:
          player.usattRating !== undefined && player.usattRating !== null
            ? String(player.usattRating)
            : "",
        [inputs.USATT_ID]:
          player.usattId !== undefined && player.usattId !== null
            ? String(player.usattId)
            : "",
        [inputs.PAYMENT]: player.payment
          ? paymentOptions.find(option => option.value === player.payment)
          : paymentOptions[0],
        [inputs.USATT_PRODUCT]: player.usattProductCode
          ? usattProductOptions.find(
              option => option.value === player.usattProductCode
            )
          : usattProductOptions[0],
        [inputs.CHECKED_IN]: player.checkedIn
          ? checkedInOptions[player.checkedIn ? 1 : 0]
          : checkedInOptions[0],
      },
    });
  };

  _saveEdits = () => {
    this.setState({ loading: true });
    const tournamentId = this.props.match.params.id;
    const updatedPlayer = {
      id: this.state.player.id,
      firstName: this.state.inputs[inputs.FIRST_NAME],
      lastName: this.state.inputs[inputs.LAST_NAME],
      usattRating: this.state.inputs[inputs.RATING] || undefined,
      usattId: this.state.inputs[inputs.USATT_ID] || "",
      payment: this.state.inputs[inputs.PAYMENT].value || undefined,
      usattProductCode: this.state.inputs[inputs.USATT_PRODUCT].value,
      checkedIn: this.state.inputs[inputs.CHECKED_IN].value,
    };
    return tournamentPlayers
      .editPlayer(tournamentId, updatedPlayer)
      .then(this._updateStatePlayer)
      .then(() => this.setState({ loading: false }));
  };

  _onClickDelete = () => {
    const didConfirm = window.confirm(
      "Are you sure you want to delete? This cannot be undone."
    );
    if (!didConfirm) {
      return;
    }

    this.setState({ loading: true });
    const tournamentId = this.props.match.params.id;
    return tournamentPlayers
      .deletePlayer(tournamentId, this.state.player.id)
      .then(() =>
        this.props.history.push(routeHelpers.tournamentPlayers(tournamentId))
      );
  };

  _onChangeInput = event => {
    const key = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [key]: value,
      },
    }));
  };

  _renderInput = (label, key) => {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor={key}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <TextInput
            className="max-w-lg block w-full sm:max-w-xs sm:text-sm"
            id={key}
            name={key}
            value={this.state.inputs[key]}
            onChange={this._onChangeInput}
            disabled={this.state.loading}
          />
        </div>
      </div>
    );
  };

  render() {
    if (!this.state.player) {
      return null;
    }

    return (
      <div className="p-6">
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <div className="flex flex-row flex-wrap gap-2 items-start">
          <Card className="flex-auto p-8">
            <StyledButton
              className="mr-3"
              onClick={this._saveEdits}
              disabled={this.state.loading}
            >
              Save
            </StyledButton>
            <StyledButton
              onClick={this._onClickDelete}
              disabled={this.state.loading}
            >
              Delete
            </StyledButton>
            <div className="pt-4 space-y-6 sm:pt-6 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                {this._renderInput("First Name", inputs.FIRST_NAME)}
                {this._renderInput("Last Name", inputs.LAST_NAME)}
                {this._renderInput("Rating", inputs.RATING)}
                {this._renderInput("USATT #", inputs.USATT_ID)}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor={inputs.PAYMENT}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Payment
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      className="max-w-lg block w-full sm:max-w-xs sm:text-sm"
                      value={this.state.inputs[inputs.PAYMENT]}
                      onChange={selectedOption => {
                        this.setState(prevState => ({
                          inputs: {
                            ...prevState.inputs,
                            [inputs.PAYMENT]: selectedOption,
                          },
                        }));
                      }}
                      options={paymentOptions}
                      disabled={this.state.loading}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor={inputs.USATT_PRODUCT}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Membership Purchase
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      className="max-w-lg block w-full sm:max-w-xs sm:text-sm"
                      value={this.state.inputs[inputs.USATT_PRODUCT]}
                      onChange={selectedOption => {
                        this.setState(prevState => ({
                          inputs: {
                            ...prevState.inputs,
                            [inputs.USATT_PRODUCT]: selectedOption,
                          },
                        }));
                      }}
                      options={usattProductOptions}
                      disabled={this.state.loading}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor={inputs.CHECKED_IN}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Checked In?
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      className="max-w-lg block w-full sm:max-w-xs sm:text-sm"
                      value={this.state.inputs[inputs.CHECKED_IN]}
                      onChange={selectedOption => {
                        this.setState(prevState => ({
                          inputs: {
                            ...prevState.inputs,
                            [inputs.CHECKED_IN]: selectedOption,
                          },
                        }));
                      }}
                      options={checkedInOptions}
                      disabled={this.state.loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="flex-auto md:max-w-sm p-8">
            <div className="font-bold">USATT Info</div>
            {this.state.player.usattPlayer ? (
              <div>
                <div className="flex flex-row">
                  <div className="mr-1">ID:</div>
                  <div>{this.state.player.usattPlayer.usattId}</div>
                </div>
                <div className="flex flex-row">
                  <div className="mr-1">First Name:</div>
                  <div>{this.state.player.usattPlayer.firstName}</div>
                </div>
                <div className="flex flex-row">
                  <div className="mr-1">Last Name:</div>
                  <div>{this.state.player.usattPlayer.lastName}</div>
                </div>
                <div className="flex flex-row">
                  <div className="mr-1">Rating:</div>
                  <div>{this.state.player.usattPlayer.usattRating}</div>
                </div>
                <div className="flex flex-row">
                  <div className="mr-1">Birth Date:</div>
                  <div>
                    {stringToFormattedDate(
                      this.state.player.usattPlayer.birthDate
                    )}{" "}
                    (Age:{" "}
                    {getAgeToday(
                      stringToDate(this.state.player.usattPlayer.birthDate)
                    )}
                    )
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="mr-1">Membership Expiration:</div>
                  <div>
                    {stringToFormattedDate(
                      this.state.player.usattPlayer.membershipExpirationDate
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(TournamentPlayerScreen);
