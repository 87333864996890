import React from "react";
import styles from "./NavMenuItem.module.css";
import { NavLink, NavLinkProps } from "react-router-dom";

type NavMenuItemProps = {
  label: string;
  to: string;
  icon?: React.ReactNode;
} & NavLinkProps;

function NavMenuItem(props: NavMenuItemProps) {
  return (
    <NavLink
      className={styles.container}
      activeClassName={styles.activeLink}
      {...props}
      to={props.to}
    >
      {props.icon ? (
        <div className={styles.iconContainer}>{props.icon}</div>
      ) : null}
      <div>{props.label}</div>
    </NavLink>
  );
}

export default NavMenuItem;
