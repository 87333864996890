/** @jsx jsx */
import Card from "../Card";
import io from "socket.io-client";
import PropTypes from "prop-types";
import React from "react";
// import TournamentBracket from "../TournamentBracket";
import TournamentBracketBase from "../TournamentBracketBase";
import { arrayToObjById } from "../../lib/util";
import { jsx } from "@emotion/core";
import { withRouter } from "react-router-dom";

const styles = {
  container: {
    padding: 24,
  },
  tableCard: {
    maxWidth: 800,
  },
  table: {
    padding: 16,
  },
  smallCell: {
    width: 32,
  },
  largeCell: {
    width: 150,
  },
  eventContainer: {
    marginTop: 12,
    marginBottom: 12,
  },
  groupsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  groupCard: {
    display: "flex",
    flexDirection: "column",
    width: 280,
    padding: 16,
    margin: 8,
  },
};

const strings = {
  title: "Draws",
};

class TournamentDrawsScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    groups: [],
    loading: true,
    selectedGroupIndex: null,
    tournament: null,
    displayFullName: true,
  };

  componentDidMount() {
    const tournamentId = this.props.match.params.id;
    this._socket = io.connect(process.env.REACT_APP_API_ENDPOINT);
    this._socket.on("connect", () => {
      // Join tournament room
      // Also rejoins tournament room if we have to reconnect.
      this._socket.emit("join-tournament", tournamentId);
    });

    this._socket.on("tournament-updated", tournament => {
      let groups = tournament.events.map(e => {
        return e.groups.map(g => ({ ...g, eventName: e.name }));
      });
      groups = groups.flat();

      this.setState({
        tournament: {
          ...tournament,
          playersById: arrayToObjById(tournament.players),
        },
        groups: groups.map(g => {
          return { ...g, fullName: `${g.eventName} - ${g.name}` };
        }),
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this._socket.disconnect();
  }

  _closeGroupModal = () => this.setState({ selectedGroupIndex: null });
  _openGroupModal = groupId => this.setState({ selectedGroupIndex: groupId });

  _toggleDisplayFullName = () =>
    this.setState(prevState => ({
      displayFullName: !prevState.displayFullName,
    }));

  _goToGroup = (_, index) => {
    this._openGroupModal(index);
  };

  _getPlayerNames = match => {
    if (!this.state.displayFullName) {
      return [match.player1Letter, match.player2Letter];
    }

    const playersById = this.state.tournament.playersById;
    const player1 = playersById[match.player1Id];
    const player2 = playersById[match.player2Id];
    const p1Name = player1
      ? `${match.player1Letter || ""}. ${player1.firstName} ${player1.lastName}`
      : "";
    const p2Name = player2
      ? `${match.player2Letter || ""}. ${player2.firstName} ${player2.lastName}`
      : "";
    return [p1Name, p2Name];
  };

  _renderTournamentBracket = event => {
    return (
      <div key={event.id} css={styles.eventContainer}>
        <div style={{ paddingBottom: 8, paddingLeft: 8 }}>
          <b>{event.name}</b>
        </div>
        <TournamentBracketBase
          key={event.id}
          rounds={event.groups.map(round =>
            round.matches.map(match => {
              const [p1Name, p2Name] = this._getPlayerNames(match);
              return {
                player1Children: p1Name,
                player2Children: p2Name,
              };
            })
          )}
        />
      </div>
    );
    // return (
    //   <TournamentBracket
    //     key={event.id}
    //     event={event}
    //     playersById={playersById}
    //   />
    // );
  };

  render() {
    if (!this.state.tournament) {
      return null;
    }

    const playersById = this.state.tournament.playersById;
    return (
      <div css={styles.container}>
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        {this.state.tournament.events
          .filter(event => event.type === "ROUND_ROBIN")
          .map(event => {
            return (
              <div key={event.id} css={styles.eventContainer}>
                <div style={{ paddingBottom: 8, paddingLeft: 8 }}>
                  <b>{event.name}</b>
                </div>
                <div css={styles.groupsContainer}>
                  {event.groups.map((group, gIndex) => {
                    return (
                      <Card key={gIndex} cssStyle={styles.groupCard}>
                        <div>
                          <b>
                            <u>{group.name}</u>
                          </b>
                        </div>
                        <div>
                          {group.playerIds
                            .filter(playerId => !!playerId)
                            .map((playerId, i) => {
                              const p = playersById[playerId];
                              return (
                                <div key={i}>{`${String.fromCharCode(
                                  65 + i
                                )}. ${p.firstName} ${p.lastName} (${
                                  p.usattRating
                                })`}</div>
                              );
                            })}
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            );
          })}
        {this.state.tournament.events
          .filter(event => event.type === "SINGLE_ELIMINATION")
          .map(event => {
            return this._renderTournamentBracket(event);
          })}
      </div>
    );
  }
}

export default withRouter(TournamentDrawsScreen);
