/** @jsx jsx */
import classNames from "classnames";
import { jsx } from "@emotion/core";

type Props = {
  children: React.ReactNode;
  cssStyle?: any;
  className?: string;
};

function Card(props: Props) {
  return (
    <div
      className={classNames("bg-white rounded-lg shadow", props.className)}
      css={props.cssStyle}
    >
      {props.children}
    </div>
  );
}

export default Card;
