import * as account from "../../../lib/api/account";
import * as routeNames from "../../../constants/routeNames";
import Card from "../../Card";
import PropTypes from "prop-types";
import React, { useState } from "react";
import StyledButton from "../../StyledButton";
import styles from "./ForgotPasswordScreen.module.css";
import TextInput from "../../TextInput";
import { Link, withRouter } from "react-router-dom";

function ForgotPasswordScreen(props) {
  const [email, setEmail] = useState("");
  const [successful, setSuccessful] = useState(false);

  const _submitForm = e => {
    e.preventDefault();

    account
      .forgotPassword(email)
      .then(() => {
        setSuccessful(true);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Forgot Password</div>
      <Card className={styles.card}>
        {!successful ? (
          <form onSubmit={_submitForm}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>{"Email"}</div>
              <TextInput
                className={styles.textInput}
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete="username"
              />
            </div>

            <div className={styles.bottomRow}>
              <StyledButton type="submit">Submit</StyledButton>
              <Link className={styles.loginLink} to={routeNames.LOGIN}>
                Login
              </Link>
            </div>
          </form>
        ) : (
          <div>Success! Please check your email for instructions.</div>
        )}
      </Card>
    </div>
  );
}

ForgotPasswordScreen.propTypes = {
  history: PropTypes.any,
};

export default withRouter(ForgotPasswordScreen);
