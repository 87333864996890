import * as routeNames from "../../constants/routeNames";
import PropTypes from "prop-types";
import React from "react";
import TextInput from "../TextInput";
import withFirebaseAuth from "../../lib/withFirebaseAuth";
import { Link, withRouter } from "react-router-dom";

const inputs = {
  EMAIL: "email",
  PASSWORD: "password",
};

// TODO: Fix React state no-op in login? Might not be from this screen.
class LoginScreen extends React.Component {
  static propTypes = {
    history: PropTypes.any,
    signInWithEmailAndPassword: PropTypes.func.isRequired,
  };

  state = {
    inputs: {
      [inputs.EMAIL]: "",
      [inputs.PASSWORD]: "",
    },
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.user && !!this.props.user) {
      this._goToAuthenticatedScreen();
    }
  }

  _goToAuthenticatedScreen = () => {
    this.props.history.push(routeNames.TOURNAMENTS);
  };

  _loginWithEmailAndPassword = e => {
    e.preventDefault();

    const email = this.state.inputs[inputs.EMAIL];
    const password = this.state.inputs[inputs.PASSWORD];
    // TODO: Loading state
    this.props.signInWithEmailAndPassword(email, password).then(data => {
      if (!!data.user) {
        this._goToAuthenticatedScreen();
        return;
      }
      if (data.message) {
        if (data.code === "auth/invalid-email") {
          alert("Invalid email format.");
          return;
        }
        if (
          data.code === "auth/user-not-found" ||
          data.code === "auth/wrong-password"
        ) {
          alert("Email or password is invalid.");
          return;
        }
        alert(data.message);
        return;
      }
    });
  };

  _onChangeInput = event => {
    const key = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [key]: value,
      },
    }));
  };

  render() {
    return (
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            TT Director
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              onSubmit={this._loginWithEmailAndPassword}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <TextInput
                    id="email"
                    name={inputs.EMAIL}
                    type="email"
                    value={this.state.inputs[inputs.EMAIL]}
                    onChange={this._onChangeInput}
                    autoComplete="username"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <TextInput
                    id="password"
                    type="password"
                    name={inputs.PASSWORD}
                    value={this.state.inputs[inputs.PASSWORD]}
                    onChange={this._onChangeInput}
                    autoComplete="current-password"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                <div className="text-sm">
                  <Link
                    className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline"
                    to={routeNames.FORGOT_PASSWORD}
                  >
                    Forgot Password
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withFirebaseAuth(LoginScreen));
