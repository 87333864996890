/** @jsx jsx */
import { jsx } from "@emotion/core";
import classNames from "classnames";
import React from "react";

type Props = {
  children: React.ReactNode;
  cssStyle?: any;
  onClick: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function StyledButton(props: Props) {
  const { children, cssStyle, onClick, ...restOfProps } = props;
  return (
    <button
      onClick={onClick}
      css={cssStyle}
      {...restOfProps}
      className={classNames(
        "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-indigo-400",
        props.className
      )}
    >
      {children}
    </button>
  );
}

export default StyledButton;
