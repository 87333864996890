import differenceInYears from "date-fns/differenceInYears";
import format from "date-fns/format";
import parse from "date-fns/parse";

export function stringToDate(str: string) {
  return parse(str, "yyyy-MM-dd", new Date());
}

export function dateToFormattedDate(date: Date): string {
  return format(date, "M/d/yyyy");
}

export function stringToFormattedDate(date: string): string {
  return dateToFormattedDate(stringToDate(date));
}

export function getAgeToday(birthDate: Date): number {
  const age = differenceInYears(new Date(), birthDate);
  return age;
}
