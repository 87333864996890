import instance from "./instance";

export function getPlayers(search, start, limit, tournamentId = undefined) {
  return instance
    .request({
      method: "GET",
      url: `/usatt-players`,
      params: {
        search,
        start,
        limit,
        tournamentId,
      },
    })
    .then(response =>
      response.data.map(p => ({
        ...p,
        fullName: `${p.firstName} ${p.lastName}`,
      }))
    );
}
