import { getDeviceId, updateDeviceId } from "../../lib/device";
import { HomeIcon } from "@heroicons/react/solid";
import { isGameCompleted, gameScores } from "../../lib/scoresUtil";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Card from "../Card";
import classNames from "classnames";
import io from "socket.io-client";
import React, { useEffect, useState, useRef } from "react";
import Table from "../Table";
import TextInput from "../TextInput";
import MatchInputCard from "../MatchInputCard";
import * as tournamentEvents from "../../lib/api/tournamentEvents";
import useMenuStore from "../../lib/useMenuStore";
import {
  Player,
  Match,
  getPlayerNames,
  displayMatchStatus,
} from "../../lib/matchUtil";

function DeviceInfoForm(props: { onSubmitSuccess: () => void }) {
  const [deviceId, setDeviceId] = useState("");

  useEffect(() => {
    setDeviceId(getDeviceId() || "");
  }, []);

  return (
    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Device Information
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            The information will be associated with this specific device.
          </p>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form
            onSubmit={e => {
              e.preventDefault();
              updateDeviceId(deviceId);
              props.onSubmitSuccess();
            }}
          >
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="deviceId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Device ID
                </label>
                <TextInput
                  id="deviceId"
                  name="deviceId"
                  value={deviceId}
                  // @ts-ignore
                  onChange={e => setDeviceId(e.target.value)}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

type BreadcrumbPage = { name: string; onClick?: () => void; current: boolean };

function Breadcrumbs(props: {
  className?: string;
  onClickHome?: () => void;
  pages?: BreadcrumbPage[];
}) {
  return (
    <nav
      className={classNames("flex h-11", props.className)}
      aria-label="Breadcrumb"
    >
      <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
        <li className="flex">
          <button
            className="flex items-center disabled:opacity-50"
            disabled={!props.onClickHome}
            onClick={props.onClickHome}
          >
            <div className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </div>
          </button>
        </li>
        {(props.pages || []).map(page => (
          <li key={page.name} className="flex">
            <button
              className="flex items-center disabled:opacity-50"
              disabled={!page.onClick}
              onClick={page.onClick}
            >
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? "page" : undefined}
              >
                {page.name}
              </span>
            </button>
          </li>
        ))}
      </ol>
    </nav>
  );
}

function TournamentScoresScreen(props: RouteComponentProps<{ id: string }>) {
  const [deviceId, setDeviceId] = useState("");
  const [deviceInfoFormVisible, setDeviceInfoFormVisible] = useState(false);

  useEffect(() => {
    const dId = getDeviceId() || "";
    setDeviceId(dId);
    if (!dId) {
      setDeviceInfoFormVisible(true);
    }
  }, []);

  const [tournament, setTournament] = useState<{
    id: string;
    events: {
      id: string;
      name: string;
      groups: {
        id: string;
        name: string;
        matches: Match[];
        index: number;
      }[];
      maxGames: number;
    }[];
    players: Player[];
  }>();
  useEffect(() => {
    if (!deviceId) {
      return;
    }
    const tournamentId = props.match.params.id;
    const socket = io(process.env.REACT_APP_API_ENDPOINT || "");
    socket.on("connect", () => {
      // Join tournament room
      // Also rejoins tournament room if we have to reconnect.
      socket.emit("join-tournament", tournamentId, deviceId);
    });
    socket.on("tournament-updated", tournament => {
      setTournament(tournament);
    });
    return () => {
      socket.disconnect();
    };
  }, [props.match.params.id, deviceId]);

  // TODO: How to sync these states???
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const selectedEvent = (tournament?.events || []).find(
    event => event.id === selectedEventId
  );
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const selectedGroup = (tournament?.events || [])
    .flatMap(event => event.groups)
    .find(group => group.id === selectedGroupId);
  const [selectedMatchId, setSelectedMatchId] = useState<string>();
  const selectedMatch = (tournament?.events || [])
    .flatMap(event => event.groups)
    .flatMap(group => group.matches)
    .find(match => match.id === selectedMatchId);

  const playersById: Record<string, Player> = {};
  for (const player of tournament?.players || []) {
    playersById[player.id] = player;
  }

  function renderTournament() {
    if (!tournament || !!selectedEvent) {
      return null;
    }

    return (
      <>
        <h2 className="mt-4 ml-2 font-bold text-lg">Events</h2>
        <Table
          className="mt-2"
          bodyClassName="text-xl"
          definition={[{ title: "Name", key: "name" }]}
          data={tournament.events}
          onClick={eventId => setSelectedEventId(eventId)}
        />
      </>
    );
  }

  function renderEvent() {
    if (!tournament || !selectedEvent || !!selectedGroup) {
      return null;
    }

    return (
      <>
        <h2 className="mt-4 ml-2 font-bold text-lg">
          {selectedEvent.name}: Groups
        </h2>
        <Table
          className="mt-2"
          bodyClassName="text-xl"
          definition={[{ title: "Name", key: "name" }]}
          data={selectedEvent.groups}
          onClick={groupId => setSelectedGroupId(groupId)}
        />
      </>
    );
  }

  function renderGroup() {
    if (!tournament || !selectedGroup || !!selectedMatch) {
      return null;
    }

    return (
      <>
        <h2 className="mt-4 ml-2 font-bold text-lg">
          {selectedGroup.name}: Matches
        </h2>
        <Table
          className="mt-2"
          bodyClassName="text-xl"
          definition={[
            {
              title: "Name",
              key: "name",
              renderer: (_, match) => {
                const [player1Name, player2Name] = getPlayerNames(
                  match,
                  playersById
                );
                const matchCompleted =
                  match.status === "COMPLETED" ||
                  match.defaultedPlayer !== null;
                const matchName = `${match.index +
                  1}) ${player1Name} vs. ${player2Name}`;
                let matchStatus =
                  matchCompleted || match.status === "IN_PROGRESS"
                    ? ` (${displayMatchStatus(match.status)})`
                    : null;
                if (match.defaultedPlayer !== null) {
                  matchStatus = " (Completed)";
                }

                const { player1GameWinCount, player2GameWinCount } = gameScores(
                  match.games
                );
                return (
                  <span>
                    {matchName}
                    <span
                      className={classNames(
                        matchCompleted && "text-green-600",
                        match.status === "IN_PROGRESS" && "text-amber-500"
                      )}
                    >
                      {matchStatus}
                    </span>
                    {match.status === "COMPLETED" ||
                    match.status === "IN_PROGRESS" ? (
                      <span>
                        {" "}
                        ({player1GameWinCount} - {player2GameWinCount})
                      </span>
                    ) : null}
                  </span>
                );
              },
            },
          ]}
          data={selectedGroup.matches}
          onClick={matchId => setSelectedMatchId(matchId)}
        />
      </>
    );
  }

  const [matchPlayer1Name, matchPlayer2Name] = selectedMatch
    ? getPlayerNames(selectedMatch, playersById)
    : ["", ""];
  const selectedMatchName = `${matchPlayer1Name} vs. ${matchPlayer2Name}`;

  const [selectedScore, setSelectedScore] = useState<{
    gameIndex: number;
    playerNum: 1 | 2;
  } | null>(null);
  const [lastModifiedGameIndex, setLastModifiedGameIndex] = useState<number>();
  const [score1, setScore1] = useState<number | null>(null);
  const [score2, setScore2] = useState<number | null>(null);
  const [invalidGames, setInvalidGames] = useState<number[]>([]);

  function renderMatch() {
    if (!tournament || !selectedEvent || !selectedMatch) {
      return null;
    }

    const modifiedGames = [...selectedMatch.games];
    const modifiedGameIndex =
      selectedScore?.gameIndex === undefined
        ? lastModifiedGameIndex
        : selectedScore.gameIndex;
    if (modifiedGameIndex !== undefined && selectedScore) {
      const currentGame = {
        ...modifiedGames[modifiedGameIndex],
        score1: score1,
        score2: score2,
      };
      modifiedGames[modifiedGameIndex] = currentGame;
    }

    return (
      <div className="flex flex-col" onClick={clearInternalScore}>
        <h2 className="mt-4 ml-2 font-bold text-lg">{selectedMatchName}</h2>
        <MatchInputCard
          className="mt-8 self-center"
          match={selectedMatch}
          games={modifiedGames}
          playersById={playersById}
          maxGames={selectedEvent.maxGames}
          onSelectScore={ss => {
            if (ss?.gameIndex !== selectedScore?.gameIndex) {
              setLastModifiedGameIndex(selectedScore?.gameIndex);
              setInvalidGames([]);

              if (ss && ss.gameIndex !== undefined) {
                const curGame = selectedMatch.games[ss.gameIndex];
                setScore1(curGame.score1);
                setScore2(curGame.score2);
              }
            }
            setSelectedScore(ss);
          }}
          selectedScore={selectedScore}
          invalidGames={invalidGames}
        />
        <div style={{ height: 120 + 4 }}></div>
        {selectedScore !== null ? (
          <div
            style={{ height: 120 + 4, paddingTop: 4 }}
            className="fixed bottom-0 left-0 right-0 w-full flex flex-col bg-zinc-100 px-0 md:px-4"
          >
            <div className="flex-1 flex flex-row justify-around">
              {renderKeypadButton(0)}
              {renderKeypadButton(1)}
              {renderKeypadButton(2)}
              {renderKeypadButton(3)}
              {renderKeypadButton(4)}
              {renderKeypadButton(5)}
              {renderKeypadButton(6)}
              {renderKeypadButton(7)}
              {renderKeypadButton(8)}
              {renderKeypadButton(9)}
              {renderKeypadButton(10)}
              {renderKeypadButton(11)}
            </div>
            <div className="flex-1 flex flex-row content-around">
              {renderKeypadButton(12)}
              {renderKeypadButton(13)}
              {renderKeypadButton(14)}
              {renderKeypadButton(15)}
              {renderKeypadButton(16)}
              {renderKeypadButton(17)}
              {renderKeypadButton(18)}
              {renderKeypadButton(19)}
              {renderKeypadButton(20)}
              {renderKeypadButton(21)}
              {renderKeypadButton(22)}
              {renderKeypadButton(23)}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  const submitScores = (
    submitScore1: number | null,
    submitScore2: number | null
  ) => {
    if (!tournament || !selectedScore || !selectedGroup || !selectedMatch) {
      return;
    }

    if (submitScore1 === null || submitScore2 === null) {
      // TODO: Maybe show alert?
      return;
    }

    if (
      submitScore1 !== null &&
      submitScore2 !== null &&
      !isGameCompleted({ score1: submitScore1, score2: submitScore2 })
    ) {
      // Invalid score.
      setInvalidGames([selectedScore.gameIndex]);
      return;
    } else {
      setInvalidGames([]);
    }

    // Submit Scores to Server
    return tournamentEvents
      .setGameScore(
        tournament.id,
        selectedEventId,
        selectedGroup.index,
        selectedMatch.index,
        selectedScore.gameIndex,
        submitScore1,
        submitScore2
      )
      .then(() => {
        // TODO: Success
      })
      .catch(err => {
        console.log(err);
      });
  };

  const changeScore1 = (newScore1: number | string) => {
    const parsedScore = parseInt(newScore1 as string);
    if (isNaN(parsedScore)) {
      setScore1(score1);
      submitScores(score1, score2);
    } else {
      setScore1(parsedScore);
      submitScores(parsedScore, score2);
    }
  };

  const changeScore2 = (newScore2: number | string) => {
    const parsedScore = parseInt(newScore2 as string);
    if (isNaN(parsedScore)) {
      setScore2(score2);
      submitScores(score1, score2);
    } else {
      setScore2(parsedScore);
      submitScores(score1, parsedScore);
    }
  };

  const onSelectScoreNum = (score: number) => {
    if (selectedScore?.playerNum === 1) {
      changeScore1(score);
    } else {
      changeScore2(score);
    }
  };

  function renderKeypadButton(num: number) {
    return (
      <button
        className="flex-1 p-1"
        onClick={e => {
          e.stopPropagation();
          onSelectScoreNum(num);
        }}
      >
        <div
          style={{ height: 50 }}
          className="flex-1 flex flex-col justify-center items-center rounded border border-gray-500"
        >
          <span className="text-2xl">{num}</span>
        </div>
      </button>
    );
  }

  const [groupLocked, setGroupLocked] = useState(false);

  const clearInternalScore = () => {
    setSelectedScore(null);
    setScore1(null);
    setScore2(null);
  };

  const onClickBreadcrumbHome = !groupLocked
    ? () => {
        setSelectedEventId(undefined);
        setSelectedGroupId(undefined);
        setSelectedMatchId(undefined);
        clearInternalScore();
      }
    : undefined;
  const breadcrumbPages: BreadcrumbPage[] = [];
  if (selectedEvent) {
    breadcrumbPages.push({
      name: selectedEvent.name,
      onClick: !groupLocked
        ? () => {
            setSelectedGroupId(undefined);
            setSelectedMatchId(undefined);
            clearInternalScore();
          }
        : undefined,
      current: false,
    });
  }
  if (selectedGroup) {
    breadcrumbPages.push({
      name: selectedGroup.name,
      onClick: () => {
        setSelectedMatchId(undefined);
        clearInternalScore();
      },
      current: false,
    });
  }
  if (selectedMatch) {
    breadcrumbPages.push({
      name: selectedMatchName,
      onClick: () => {},
      current: false,
    });
  }

  const shouldShowGlobalMenu = useMenuStore(state => state.shouldShowMenu);
  const hideGlobalMenu = useMenuStore(state => state.hideGlobalMenu);
  const showGlobalMenu = useMenuStore(state => state.showGlobalMenu);

  const scoreInputScreenRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={scoreInputScreenRef} className="p-6">
      {deviceInfoFormVisible ? (
        <DeviceInfoForm
          onSubmitSuccess={() => {
            const dId = getDeviceId() || "";
            setDeviceId(dId);
            if (dId) {
              setDeviceInfoFormVisible(false);
            }
          }}
        />
      ) : (
        <Card
          className={classNames(
            "px-4 py-2 flex flex-row items-center",
            !shouldShowGlobalMenu && "opacity-70"
          )}
        >
          <div className="flex-1">Device ID: {deviceId}</div>
          {shouldShowGlobalMenu ? (
            <button
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setDeviceInfoFormVisible(true)}
            >
              Edit Device
            </button>
          ) : null}
          {selectedGroup ? (
            <button
              className={classNames(
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                shouldShowGlobalMenu
                  ? "bg-indigo-600 hover:bg-indigo-700"
                  : "bg-gray-400"
              )}
              onClick={() => setGroupLocked(!groupLocked)}
            >
              {groupLocked ? "Unlock Group" : "Lock Group"}
            </button>
          ) : null}
          <button
            className={classNames(
              "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
              shouldShowGlobalMenu
                ? "bg-indigo-600 hover:bg-indigo-700"
                : "bg-gray-400"
            )}
            onClick={shouldShowGlobalMenu ? hideGlobalMenu : showGlobalMenu}
          >
            {shouldShowGlobalMenu ? "Hide Menu" : "Show Menu"}
          </button>
          {false ? (
            <button
              className={classNames(
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                "bg-indigo-600 hover:bg-indigo-700"
              )}
              onClick={() => {
                const element = scoreInputScreenRef.current;
                if (!element) {
                  return;
                }
                const requestFullscreenFunction =
                  element.requestFullscreen ||
                  // @ts-ignore
                  element.webkitRequestFullscreen ||
                  // @ts-ignore
                  element.webkitRequestFullScreen ||
                  // @ts-ignore
                  element.mozRequestFullScreen ||
                  // @ts-ignore
                  element.msRequestFullscreen ||
                  undefined;

                const fullscreenElement =
                  document.fullscreenElement || // alternative standard method
                  // @ts-ignore
                  document.mozFullScreenElement ||
                  // @ts-ignore
                  document.webkitFullscreenElement ||
                  // @ts-ignore
                  document.msFullscreenElement;
                if (fullscreenElement) {
                  if (document.exitFullscreen) {
                    document.exitFullscreen();
                    // @ts-ignore
                  } else if (document.msExitFullscreen) {
                    // @ts-ignore
                    document.msExitFullscreen();
                    // @ts-ignore
                  } else if (document.mozCancelFullScreen) {
                    // @ts-ignore
                    document.mozCancelFullScreen();
                    // @ts-ignore
                  } else if (document.webkitExitFullscreen) {
                    // @ts-ignore
                    document.webkitExitFullscreen();
                  }
                } else {
                  if (requestFullscreenFunction) {
                    requestFullscreenFunction.apply(element);
                  }
                }
              }}
            >
              Fullscreen
            </button>
          ) : null}
        </Card>
      )}

      {tournament ? (
        <div className="mt-4 flex flex-row items-center">
          <Breadcrumbs
            className="flex-1"
            onClickHome={onClickBreadcrumbHome}
            pages={breadcrumbPages}
          />
          {groupLocked && selectedMatch ? (
            <button
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                setSelectedMatchId(undefined);
                clearInternalScore();
              }}
            >
              Back to Group
            </button>
          ) : null}
        </div>
      ) : null}

      {renderTournament()}
      {renderEvent()}
      {renderGroup()}
      {renderMatch()}
    </div>
  );
}

export default withRouter(TournamentScoresScreen);
