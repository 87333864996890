/** @jsx jsx */
import classNames from "classnames";
import { jsx } from "@emotion/core";

type Props = {
  name: string;
  value: string;
  cssStyle?: any;
} & React.HTMLProps<HTMLInputElement>;

function TextInput(props: Props) {
  const { cssStyle, name, onChange, value, className, ...restOfProps } = props;
  return (
    <input
      type="text"
      name={name}
      css={cssStyle}
      value={value}
      onChange={onChange}
      className={classNames(
        "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
        className
      )}
      {...restOfProps}
    />
  );
}

export default TextInput;
