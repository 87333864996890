export const HOME = "/";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const PROFILE = "/profile";
export const TOURNAMENTS = "/tournaments";
export const USATT_PLAYERS = "/usatt-players";
export const TOURNAMENT = "/tournaments/:id";
export const TOURNAMENT_OVERVIEW = "/tournaments/:id/overview";
export const TOURNAMENT_DRAWS = "/tournaments/:id/draws";
export const TOURNAMENT_EVENTS = "/tournaments/:id/events";
export const TOURNAMENT_EVENT = "/tournaments/:id/events/:eventId";
export const TOURNAMENT_PLAYERS = "/tournaments/:id/players";
export const TOURNAMENT_PLAYER = "/tournaments/:id/players/:playerId";
export const TOURNAMENT_ACCESS = "/tournaments/:id/access";
export const TOURNAMENT_DEVICES = "/tournaments/:id/devices";
export const TOURNAMENT_SCORES = "/tournaments/:id/scores";
