/** @jsx jsx */
import PropTypes from "prop-types";
import React from "react";
import { jsx } from "@emotion/core";

const styles = {
  bracketContainer: {
    display: "flex",
    flexDirection: "row",
  },
  round: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 200,
    listStyle: "none",
    padding: 0,
  },
  roundSpacer: {
    flexGrow: 1,
  },
  firstLastRoundSpace: {
    flexGrow: 0.5,
  },
  gameSpacer: {
    flexGrow: 1,
    borderRight: "1px solid #aaa",
    minHeight: 40,
  },
  gameScore: {
    float: "right",
    marginRight: 5,
  },
  game: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
  },
  topGame: {
    borderBottom: "1px solid #ccc",
  },
  bottomGame: {
    borderTop: "1px solid #ccc",
  },
  bold: {
    fontWeight: "bold",
  },
};

export default class TournamentBracketBase extends React.Component {
  static propTypes = {
    rounds: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          player1Children: PropTypes.node,
          player2Children: PropTypes.node,
        })
      )
    ),
    // event: PropTypes.object,
    // playersById: PropTypes.object,
  };

  static defaultProps = {
    // event: {},
  };

  _renderMatch = match => {
    return (
      <React.Fragment>
        <li css={[styles.game, styles.topGame, false && styles.bold]}>
          {match.player1Children}
          {/*<span css={styles.gameScore}>{player1GamesWon}</span>*/}
        </li>
        <li css={[styles.gameSpacer, styles.game]}>&nbsp;</li>
        <li css={[styles.game, styles.bottomGame, false && styles.bold]}>
          {match.player2Children}
          {/*<span css={styles.gameScore}>{player2GamesWon}</span>*/}
        </li>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div>
        {/*<h3>{this.props.event.name}</h3>*/}
        <div css={styles.bracketContainer}>
          {this.props.rounds.map((round, rIndex) => {
            return (
              <ul key={rIndex} css={styles.round}>
                <li css={[styles.roundSpacer, styles.firstLastRoundSpace]}>
                  &nbsp;
                </li>

                {round.map((match, i) => {
                  return (
                    <React.Fragment key={i}>
                      {i !== 0 ? (
                        <li css={styles.roundSpacer}>&nbsp;</li>
                      ) : null}
                      {this._renderMatch(match)}
                    </React.Fragment>
                  );
                })}

                <li css={[styles.roundSpacer, styles.firstLastRoundSpace]}>
                  &nbsp;
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    );
  }
}
