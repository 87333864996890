import instance from "./instance";

export function me() {
  return instance
    .request({
      method: "GET",
      url: `/account/me`,
    })
    .then(response => response.data);
}

export function forgotPassword(email) {
  return instance
    .request({
      method: "POST",
      url: `/account/forgot-password`,
      data: {
        email,
      },
    })
    .then(response => response.data);
}
