import * as routeNames from "../constants/routeNames";
import NavMenu from "./navigation/NavMenu";
import React from "react";
import Router from "./Router";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import NavBar from "./navigation/NavBar";
import useMenuStore from "../lib/useMenuStore";

function Unauthenticated() {
  // TODO: We probably want to break apart Router component.
  return (
    <div className="flex flex-row">
      <div className="bg-zinc-100 flex-1 h-screen overflow-y-auto">
        <Router />
      </div>
    </div>
  );
}

function Authenticated() {
  const location = useLocation();
  const isScoreInputPage = location.pathname.endsWith("/scores");
  const shouldShowMenu = useMenuStore(state => state.shouldShowMenu);
  return (
    <>
      {!isScoreInputPage ? <NavBar /> : null}
      <div className="flex-1 flex flex-col sm:flex-row lg:px-4 w-full max-w-7xl lg:mx-auto">
        {shouldShowMenu || !isScoreInputPage ? <NavMenu /> : null}
        <div className="flex-1 min-h-full overflow-y-auto pb-8">
          <Router />
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={routeNames.LOGIN} component={Unauthenticated} />
        <Route path={routeNames.FORGOT_PASSWORD} component={Unauthenticated} />
        <Route component={Authenticated} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
