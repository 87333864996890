import * as account from "../../../lib/api/account";
import Card from "../../Card";
import React, { useEffect, useState } from "react";
import styles from "./ProfileScreen.module.css";

const strings = {
  title: "Profile",
};

export default function ProfileScreen() {
  const [myAccount, setMyAccount] = useState(null);

  useEffect(() => {
    account.me().then(a => {
      setMyAccount(a);
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{strings.title}</div>
      {myAccount ? (
        <Card className={styles.card}>
          <div>First Name: {myAccount.firstName}</div>
          <div>Last Name: {myAccount.lastName}</div>
          <div>Email: {myAccount.email}</div>
        </Card>
      ) : null}
    </div>
  );
}
