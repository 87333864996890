import instance from "./instance";

function parseTournament(tournament) {
  return {
    ...tournament,
    date: tournament.date ? new Date(tournament.date) : null,
  };
}

export function getTournaments() {
  return instance
    .request({
      method: "GET",
      url: "/tournaments",
    })
    .then(response => {
      const result = {};
      Object.values(response.data).forEach(tournament => {
        result[tournament.id] = parseTournament(tournament);
      });
      return result;
    });
}

export function getTournament(tournamentId) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}`,
    })
    .then(response => parseTournament(response.data));
}

export function newTournament(tournament) {
  return instance
    .request({
      method: "POST",
      url: "/tournaments",
      data: tournament,
    })
    .then(response => parseTournament(response.data));
}

export function editTournament(tournament) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournament.id}`,
      data: tournament,
    })
    .then(response => parseTournament(response.data));
}

export function deleteTournament(tournamentId) {
  return instance
    .request({
      method: "DELETE",
      url: `/tournaments/${tournamentId}`,
    })
    .then(response => response.data);
}

export function getConnectedDevices(tournamentId) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/devices`,
    })
    .then(response => response.data);
}

export function downloadTournamentResults(tournamentId) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/results-csv`,
      responseType: "arraybuffer",
    })
    .then(response => {
      let blob = new Blob([response.data], { type: "application/zip" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "results_output.zip";
      link.click();
    });
}

export function downloadMatchCard(tournamentId, player1Id, player2Id) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/match-card`,
      params: {
        player1Id,
        player2Id,
      },
      responseType: "arraybuffer",
    })
    .then(response => {
      let blob = new Blob([response.data], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "match_card.pdf";
      link.click();
    });
}

export function downloadGroupSheet(tournamentId, groupId) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/group-sheet`,
      params: { groupId },
      responseType: "arraybuffer",
    })
    .then(response => {
      let blob = new Blob([response.data], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // TODO: Use group name in file name
      link.download = `group_sheet-${groupId}.pdf`;
      link.click();
    });
}
