import "./ReactModal.css";
import Modal from "react-modal";
import React from "react";
import styles from "./Modal.module.css";
import { FaTimes } from "react-icons/fa";

export default function ModalComponent(props: {
  isOpen: boolean;
  onRequestClose: () => void;
  title: string;
  children: React.ReactNode;
  contentLabel?: string;
  width?: number;
  height?: number;
}) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.35)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: props.width || undefined,
          height: props.height || undefined,
          maxHeight: "100%",
          border: "none",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          padding: 0,
        },
      }}
      contentLabel={props.contentLabel || `${props.title} Modal`}
      appElement={document.body}
    >
      <div>
        <div className={styles.headerRow}>
          <div className={styles.title}>{props.title}</div>
          <FaTimes
            className={styles.closeButton}
            onClick={props.onRequestClose}
          />
        </div>
        <div className={styles.contentContainer}>{props.children}</div>
      </div>
    </Modal>
  );
}
