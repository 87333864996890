import instance from "./instance";

function parsePlayer(player) {
  return {
    ...player,
    fullName: `${player.firstName} ${player.lastName}`,
  };
}

export function getPlayers(tournamentId, search) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/players`,
      params: {
        search,
      },
    })
    .then(response => response.data.map(player => parsePlayer(player)));
}

export function getPlayer(tournamentId, playerId) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/players/${playerId}`,
    })
    .then(response => parsePlayer(response.data));
}

export function newPlayer(tournamentId, player) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/players`,
      data: player,
    })
    .then(response => response.data.map(player => parsePlayer(player)));
}

export function editPlayer(tournamentId, player) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/players/${player.id}`,
      data: player,
    })
    .then(response => parsePlayer(response.data));
}

export function deletePlayer(tournamentId, playerId) {
  return instance
    .request({
      method: "DELETE",
      url: `/tournaments/${tournamentId}/players/${playerId}`,
    })
    .then(response => response.data.map(player => parsePlayer(player)));
}

export function updateRatings(tournamentId, file) {
  const data = new FormData();
  data.append("file", file);
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/ratings-file`,
      data: data,
    })
    .then(response => ({
      updateCount: response.data.updateCount,
      players: response.data.players.map(player => parsePlayer(player)),
    }));
}
