/** @jsx jsx */
import PropTypes from "prop-types";
import Select from "react-select";
import StyledButton from "./StyledButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { jsx } from "@emotion/core";
// TODO: Remove emotion

const styles = {
  container: {
    padding: 24,
  },
  addPlayerSelect: {
    maxWidth: 320,
  },
  playerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ":hover": {
      backgroundColor: "#f5f5f5",
    },
  },
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function RRPlayersList(props) {
  const _addAllPlayers = () => {
    const playersToAdd = props.playerOptions
      .filter(po => !props.players.some(p => po.value.id === p.id))
      .map(po => po.value);
    if (playersToAdd.length <= 0) {
      return {};
    }
    playersToAdd.sort((a, b) => b.usattRating - a.usattRating);
    props.onChange && props.onChange([...props.players, ...playersToAdd]);
  };

  const _orderByUSATTRating = () => {
    const nextPlayers = [...props.players];
    nextPlayers.sort((a, b) => b.usattRating - a.usattRating);
    props.onChange && props.onChange(nextPlayers);
  };

  const _removePlayer = playerId => {
    const nextPlayers = props.players.filter(p => p.id !== playerId);
    props.onChange && props.onChange(nextPlayers);
  };

  const _onChangeSelectedPlayer = option => {
    if (option === null) {
      return;
    }
    const nextPlayers = [...props.players, option.value];
    props.onChange && props.onChange(nextPlayers);
  };

  const _onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const nextPlayers = reorder(
      props.players,
      result.source.index,
      result.destination.index
    );
    props.onChange && props.onChange(nextPlayers);
  };

  return (
    <div>
      <h3 className="font-bold text-lg mb-2">Players</h3>
      <Select
        className="mb-2"
        css={styles.addPlayerSelect}
        placeholder="Add player..."
        isClearable={true}
        options={props.playerOptions.filter(
          po => !props.players.some(p => po.value.id === p.id)
        )}
        menuPortalTarget={document.body}
        value={null}
        onChange={_onChangeSelectedPlayer}
      />
      <StyledButton
        className="mr-2"
        onClick={_addAllPlayers}
        disabled={
          props.playerOptions.filter(
            po => !props.players.some(p => po.value.id === p.id)
          ).length === 0
        }
      >
        Add All
      </StyledButton>
      <StyledButton onClick={_orderByUSATTRating}>Order By Rating</StyledButton>

      <DragDropContext onDragEnd={_onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
            >
              {props.players.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      // style={getItemStyle(
                      //   snapshot.isDragging,
                      //   provided.draggableProps.style
                      // )}
                    >
                      <div css={styles.playerRow}>
                        {`${index + 1}. ${item.firstName} ${item.lastName} (${
                          item.usattRating
                        })`}
                        <StyledButton onClick={() => _removePlayer(item.id)}>
                          X
                        </StyledButton>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

RRPlayersList.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object),
  playerOptions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

export default RRPlayersList;
