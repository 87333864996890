import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

type Props = {
  active?: boolean;
  to: string;
  children: React.ReactNode;
};

export default function NavBarItem(props: Props) {
  const location = useLocation();
  return (
    <Link
      to={props.to}
      className={classNames(
        location.pathname.startsWith(props.to)
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "px-3 py-2 rounded-md text-sm font-medium"
      )}
    >
      {props.children}
    </Link>
  );
}
