/** @jsx jsx */
import * as routeHelpers from "../../lib/routeHelpers";
import * as tournaments from "../../lib/api/tournaments";
import PropTypes from "prop-types";
import StyledButton from "../StyledButton";
import Table from "../Table";
import { Component } from "react";
import { jsx } from "@emotion/core";
import { withRouter } from "react-router-dom";

const tableDef = [
  { title: "Name", key: "name" },
  {
    title: "Date",
    key: "date",
    renderer: date =>
      !isNaN(date) ? new Date(date).toLocaleDateString() : null,
  },
];

const styles = {
  container: {
    padding: 24,
  },
};

const strings = {
  title: "Tournaments",
};

class TournamentsScreen extends Component {
  static propTypes = {
    history: PropTypes.any,
  };

  state = {
    tournaments: [],
    loading: true,
  };

  componentDidMount() {
    // TODO: Store in redux?
    tournaments
      .getTournaments()
      .then(tournaments =>
        this.setState({ tournaments: Object.values(tournaments) })
      )
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  _goToAddTournament = () => {
    this.props.history.push(routeHelpers.tournament("new"));
  };

  _onClickTournament = id => {
    this.props.history.push(routeHelpers.tournament(id));
  };

  render() {
    return (
      <div css={styles.container}>
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <StyledButton onClick={this._goToAddTournament}>
          New Tournament
        </StyledButton>
        <Table
          className="max-w-4xl mt-4"
          definition={tableDef}
          data={this.state.tournaments}
          onClick={this._onClickTournament}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default withRouter(TournamentsScreen);
