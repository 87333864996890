import * as routeNames from "../constants/routeNames";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import LoginScreen from "./screens/LoginScreen";
import PrivateRoute from "./PrivateRoute";
import ProfileScreen from "./screens/ProfileScreen";
import React from "react";
import TournamentAccessScreen from "./screens/TournamentAccessScreen";
import TournamentDetailsScreen from "./screens/TournamentDetailsScreen";
import TournamentDevicesScreen from "./screens/TournamentDevicesScreen";
import TournamentDrawsScreen from "./screens/TournamentDrawsScreen";
import TournamentEventDetailsScreen from "./screens/TournamentEventDetailsScreen";
import TournamentEventsScreen from "./screens/TournamentEventsScreen";
import TournamentGroupsScreen from "./screens/TournamentGroupsScreen";
import TournamentPlayerScreen from "./screens/TournamentPlayerScreen";
import TournamentPlayersScreen from "./screens/TournamentPlayersScreen";
import TournamentScoresScreen from "./screens/TournamentScoresScreen";
import TournamentsScreen from "./screens/TournamentsScreen";
import UsattPlayersScreen from "./screens/UsattPlayersScreen";
import { Route, Switch } from "react-router-dom";

function Router() {
  return (
    <Switch>
      <PrivateRoute path={routeNames.TOURNAMENT_EVENT}>
        <TournamentEventDetailsScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENT_PLAYER}>
        <TournamentPlayerScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENT_ACCESS}>
        <TournamentAccessScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENT_DEVICES}>
        <TournamentDevicesScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENT_OVERVIEW}>
        <TournamentGroupsScreen />
      </PrivateRoute>
      <Route path={routeNames.TOURNAMENT_DRAWS}>
        <TournamentDrawsScreen />
      </Route>
      <PrivateRoute path={routeNames.TOURNAMENT_EVENTS}>
        <TournamentEventsScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENT_PLAYERS}>
        <TournamentPlayersScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENT_SCORES}>
        <TournamentScoresScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.USATT_PLAYERS}>
        <UsattPlayersScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENT}>
        <TournamentDetailsScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.PROFILE}>
        <ProfileScreen />
      </PrivateRoute>
      <PrivateRoute path={routeNames.TOURNAMENTS}>
        <TournamentsScreen />
      </PrivateRoute>
      <Route path={routeNames.LOGIN}>
        <LoginScreen />
      </Route>
      <Route path={routeNames.FORGOT_PASSWORD}>
        <ForgotPasswordScreen />
      </Route>
      <Route path="/">
        <h2>Home</h2>
      </Route>
    </Switch>
  );
}

export default Router;
