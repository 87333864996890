/** @jsx jsx */
import * as routeHelpers from "../../lib/routeHelpers";
import * as tournamentEvents from "../../lib/api/tournamentEvents";
import PropTypes from "prop-types";
import React from "react";
import StyledButton from "../StyledButton";
import Table from "../Table";
import { jsx } from "@emotion/core";
import { withRouter } from "react-router-dom";

const tableDef = [
  { title: "Name", key: "name" },
  {
    title: "Type",
    key: "type",
    renderer: type => {
      if (type === "SINGLE_ELIMINATION") {
        return "Single Elimination";
      }
      if (type === "ROUND_ROBIN") {
        return "Round Robin";
      }
      return null;
    },
  },
  { title: "Max Games", key: "maxGames" },
  {
    title: "Players",
    key: "playerIds",
    renderer: ids => ids.filter(id => !!id).length,
  },
  { title: "Groups", key: "groups", renderer: groups => groups.length },
];

const styles = {
  container: {
    padding: 24,
  },
};

const strings = {
  title: "Events",
  addNewEvent: "New Event",
};

class TournamentEventsScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    events: [],
    loading: true,
  };

  componentDidMount() {
    const tournamentId = this.props.match.params.id;
    this.setState({ loading: true });
    tournamentEvents
      .getEvents(tournamentId)
      .then(events =>
        this.setState({ events: Object.values(events), loading: false })
      );
  }

  _goToNewEvent = () => {
    const tournamentId = this.props.match.params.id;
    this.props.history.push(routeHelpers.tournamentEvent(tournamentId, "new"));
  };

  _goToEvent = eventId => {
    const tournamentId = this.props.match.params.id;
    this.props.history.push(
      routeHelpers.tournamentEvent(tournamentId, eventId)
    );
  };

  render() {
    return (
      <div css={styles.container}>
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <StyledButton onClick={this._goToNewEvent}>
          {strings.addNewEvent}
        </StyledButton>
        <Table
          className="max-w-4xl mt-4"
          definition={tableDef}
          data={this.state.events}
          onClick={this._goToEvent}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default withRouter(TournamentEventsScreen);
