type MatchStatus = "NOT_STARTED" | "IN_PROGRESS" | "COMPLETED" | "BYE";

export type Match = {
  id: string;
  index: number;
  status: MatchStatus;
  player1Letter: string | null;
  player2Letter: string | null;
  player1Id: string | null;
  player2Id: string | null;
  defaultedPlayer: number | null;
  games: any[];
};

export type Player = {
  id: string;
  firstName: string;
  lastName: string;
  usattId: string;
  usattRating: number;
};

function getPlayerDisplayName(
  player: any,
  letter: string | null | undefined,
  matchStatus: MatchStatus
) {
  if (matchStatus === "BYE" && !player) {
    return "Bye";
  }
  if (!player) {
    return "Empty";
  }
  if (!letter) {
    return `${player.firstName} ${player.lastName}`;
  }
  return `${letter}. ${player.firstName} ${player.lastName}`;
}

export function getPlayerNames(
  match: Match,
  playersById: Record<string, Player>
) {
  const player1 = match.player1Id ? playersById[match.player1Id] : null;
  const player2 = match.player2Id ? playersById[match.player2Id] : null;
  const p1Name = getPlayerDisplayName(
    player1,
    match.player1Letter,
    match.status
  );
  const p2Name = getPlayerDisplayName(
    player2,
    match.player2Letter,
    match.status
  );
  return [p1Name, p2Name];
}

export function displayMatchStatus(matchStatus: MatchStatus): string {
  if (matchStatus === "COMPLETED") {
    return "Completed";
  } else if (matchStatus === "IN_PROGRESS") {
    return "In Progress";
  } else if (matchStatus === "NOT_STARTED") {
    return "Not Started";
  } else if (matchStatus === "BYE") {
    return "Bye";
  }
  return "";
}
