import create from "zustand";

const useMenuStore = create<{
  shouldShowMenu: boolean;
  hideGlobalMenu: () => void;
  showGlobalMenu: () => void;
}>(set => ({
  shouldShowMenu: true,
  hideGlobalMenu: () => set({ shouldShowMenu: false }),
  showGlobalMenu: () => set({ shouldShowMenu: true }),
}));

export default useMenuStore;
