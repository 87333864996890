/** @jsx jsx */
import * as tournaments from "../../lib/api/tournaments";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseJSON from "date-fns/parseJSON";
import PropTypes from "prop-types";
import React from "react";
import Table from "../Table";
import { jsx } from "@emotion/core";
import { withRouter } from "react-router-dom";

const tableDef = [
  { title: "Device ID", key: "id" },
  {
    title: "Type",
    key: "type",
    renderer: type => (type === "input_device" ? "Score App" : "Web/Other"),
  },
  {
    title: "Last Seen",
    key: "lastSeen",
    renderer: lastSeen =>
      !lastSeen ? "Online" : `${formatDistanceToNow(parseJSON(lastSeen))} ago`,
  },
  {
    title: "Battery",
    key: "batteryLevel",
    renderer: (level, deviceInfo) => {
      if (!level) {
        return null;
      }
      const isCharging =
        deviceInfo.batteryState === "charging" ||
        deviceInfo.batteryState === "full";
      const description = isCharging ? " (Charging)" : "";
      return `${(level * 100).toFixed()}%` + description;
    },
  },
];

const styles = {
  container: {
    padding: 24,
  },
};

const strings = {
  title: "Connected Devices",
};

class TournamentDevicesScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
  };

  state = {
    devices: [],
    firstLoading: true,
    loading: false,
  };

  componentDidMount() {
    this._getConnectedDevices();
    this._pollingTimer = setInterval(() => this._getConnectedDevices(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this._pollingTimer);
    this._pollingTimer = null;
  }

  _getConnectedDevices = () => {
    const tournamentId = this.props.match.params.id;
    this.setState({ loading: true });
    tournaments
      .getConnectedDevices(tournamentId)
      .then(devices => this.setState({ devices }))
      .finally(() => this.setState({ firstLoading: false, loading: false }));
  };

  render() {
    return (
      <div css={styles.container}>
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <Table
          className="max-w-4xl"
          definition={tableDef}
          data={this.state.devices}
          loading={this.state.firstLoading}
        />
      </div>
    );
  }
}

export default withRouter(TournamentDevicesScreen);
