import * as routeHelpers from "../../lib/routeHelpers";
import * as tournamentPlayers from "../../lib/api/tournamentPlayers";
import AddTournamentPlayerModal from "../AddTournamentPlayerModal";
import debounce from "lodash.debounce";
import isPast from "date-fns/isPast";
import PropTypes from "prop-types";
import React from "react";
import StyledButton from "../StyledButton";
import Table from "../Table";
import TextInput from "../TextInput";
import { dateToFormattedDate, stringToDate } from "../../lib/dateUtil";
import { withRouter } from "react-router-dom";

const tableDef = [
  { title: "Name", key: "fullName" },
  { title: "USATT #", key: "usattId" },
  {
    title: "Checked In",
    key: "checkedIn",
    renderer: checkedIn => (checkedIn ? "Yes" : "No"),
  },
  {
    title: "Payment",
    key: "payment",
    renderer: payment => {
      if (!payment || payment === "unpaid") {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            Unpaid
          </span>
        );
      }
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
          {payment.charAt(0).toUpperCase() + payment.slice(1)}
        </span>
      );
    },
  },
  {
    title: "Membership",
    key: "usattPlayer",
    renderer: (player, fullPlayer) => {
      if (fullPlayer.usattProductCode) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Purchase
          </span>
        );
      }

      if (!player) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Unknown
          </span>
        );
      }
      const expDate = stringToDate(player.membershipExpirationDate);
      // TODO: Factor in tournament date
      const hasExpired = isPast(expDate);
      if (hasExpired) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            Expired: {dateToFormattedDate(expDate)}
          </span>
        );
      }
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
          Active
        </span>
      );
    },
  },
];

const strings = {
  title: "Players",
};

class TournamentPlayersScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    players: [],
    loading: true,
    searchValue: "",
    addPlayerModalOpen: false,
    selectedFile: null,
  };

  componentDidMount() {
    this._debouncedGetPlayers = debounce(this._getPlayers, 400);
    this._getPlayers();
  }

  _getPlayers = () => {
    const tournamentId = this.props.match.params.id;
    this.setState({ loading: true });
    tournamentPlayers
      .getPlayers(tournamentId, this.state.searchValue)
      .then(players => this.setState({ players, loading: false }));
  };

  _onUpdatedPlayers = players => {
    this.setState({ players });
  };

  _openAddPlayerModal = () => this.setState({ addPlayerModalOpen: true });
  _closeAddPlayerModal = () => this.setState({ addPlayerModalOpen: false });

  _onChangeFile = e => {
    this.setState({ selectedFile: e.target.files[0] });
  };

  _uploadRatingsFile = () => {
    if (!this.state.selectedFile) {
      return;
    }

    const tournamentId = this.props.match.params.id;
    tournamentPlayers
      .updateRatings(tournamentId, this.state.selectedFile)
      .then(({ updateCount, players }) => {
        this._onUpdatedPlayers(players);
        window.alert(`Uploaded ${updateCount} player(s).`);
      })
      .catch(err => {
        console.warn(err);
        window.alert("Something went wrong. Please try again later.");
      });
  };

  _goToTournamentPlayer = playerId => {
    const tournamentId = this.props.match.params.id;
    this.props.history.push(
      routeHelpers.tournamentPlayer(tournamentId, playerId)
    );
  };

  _onChangeSearchValue = event => {
    const value = event.target.value;
    this.setState({ searchValue: value });
    this._debouncedGetPlayers();
  };

  render() {
    const tournamentId = this.props.match.params.id;
    return (
      <div className="p-6">
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <div className="flex flex-row flex-wrap gap-2">
          <div className="flex flex-row items-center">
            <TextInput
              className="w-48 sm:w-60"
              value={this.state.searchValue}
              onChange={this._onChangeSearchValue}
              placeholder={"Search Players"}
            />
            <StyledButton
              className="ml-2 mr-2"
              onClick={this._openAddPlayerModal}
            >
              Add Player
            </StyledButton>
          </div>
          <div className="flex flex-row items-center">
            <input
              className="w-48 sm:w-60"
              type="file"
              onChange={this._onChangeFile}
            />
            <StyledButton className="ml-2" onClick={this._uploadRatingsFile}>
              Upload
            </StyledButton>
          </div>
        </div>
        <Table
          className="max-w-4xl mt-4"
          definition={tableDef}
          data={this.state.players}
          loading={this.state.loading}
          onClick={this._goToTournamentPlayer}
        />
        <AddTournamentPlayerModal
          isOpen={this.state.addPlayerModalOpen}
          onRequestClose={this._closeAddPlayerModal}
          tournamentId={tournamentId}
          onUpdatedPlayers={this._onUpdatedPlayers}
        />
      </div>
    );
  }
}

export default withRouter(TournamentPlayersScreen);
