/** @jsx jsx */
import * as tournaments from "../../lib/api/tournaments";
import GroupModal from "../GroupModal";
import io from "socket.io-client";
import PropTypes from "prop-types";
import React from "react";
import StyledButton from "../StyledButton";
import Table from "../Table";
import { arrayToObjById } from "../../lib/util";
import { jsx } from "@emotion/core";
import { withRouter } from "react-router-dom";

const tableDef = [
  { title: "Name", key: "fullName" },
  {
    title: "Type",
    key: "type",
    renderer: type => {
      if (type === "single_match") {
        return "Match";
      }
      if (type === "ROUND_ROBIN") {
        return "Round Robin";
      }
      if (type === "SINGLE_ELIMINATION_ROUND") {
        return "SE Round";
      }
      return null;
    },
  },
  {
    title: "Players",
    key: "playerIds",
    renderer: ids => ids.filter(id => !!id).length,
  },
  {
    title: "Matches",
    key: "matches",
    renderer: matches => {
      const completedMatches = matches.filter(
        m => m.status === "COMPLETED" || !!m.defaultedPlayer
      ).length;
      const totalMatches = matches.filter(m => m.status !== "BYE").length;
      const percentage =
        totalMatches === 0 ? 100 : (completedMatches / totalMatches) * 100;
      return (
        <span>
          {`${completedMatches}/${totalMatches} `}
          <span
            style={percentage === 100 ? { color: "green" } : null}
          >{`${percentage.toFixed(0)}%`}</span>
        </span>
      );
    },
  },
];

const styles = {
  container: {
    padding: 24,
  },
  smallCell: {
    width: 32,
  },
  largeCell: {
    width: 150,
  },
};

const strings = {
  title: "Overview",
};

class TournamentGroupsScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    groups: [],
    loading: true,
    selectedGroupIndex: null,
    tournament: null,
    displayFullName: true,
  };

  componentDidMount() {
    const tournamentId = this.props.match.params.id;
    this._socket = io.connect(process.env.REACT_APP_API_ENDPOINT);
    this._socket.on("connect", () => {
      // Join tournament room
      // Also rejoins tournament room if we have to reconnect.
      this._socket.emit("join-tournament", tournamentId);
    });

    this._socket.on("tournament-updated", tournament => {
      let groups = tournament.events.map(e => {
        return e.groups.map((g, gIndex) => ({
          ...g,
          groupIndex: gIndex,
          eventId: e.id,
          eventName: e.name,
        }));
      });
      groups = groups.flat();

      this.setState({
        tournament: {
          ...tournament,
          playersById: arrayToObjById(tournament.players),
        },
        groups: groups.map(g => {
          let playerIds = g.playerIds;
          if (!playerIds || playerIds.length === 0) {
            playerIds = [];
            g.matches.forEach(m => {
              playerIds.push(m.player1Id);
              playerIds.push(m.player2Id);
            });
          }
          return { ...g, playerIds, fullName: `${g.eventName} - ${g.name}` };
        }),
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this._socket.disconnect();
  }

  _closeGroupModal = () => this.setState({ selectedGroupIndex: null });
  _openGroupModal = groupId => this.setState({ selectedGroupIndex: groupId });

  _toggleDisplayFullName = () =>
    this.setState(prevState => ({
      displayFullName: !prevState.displayFullName,
    }));

  _goToGroup = (_, index) => {
    this._openGroupModal(index);
  };

  _downloadCSV = () => {
    tournaments.downloadTournamentResults(this.props.match.params.id);
  };

  _renderModal = () => {
    const selectedGroup = this.state.groups[this.state.selectedGroupIndex];
    if (!selectedGroup) {
      return null;
    }

    // const nameCellStyles = this.state.displayFullName
    //   ? styles.largeCell
    //   : styles.smallCell;

    const tournamentId = this.props.match.params.id;
    return (
      <GroupModal
        tournamentId={tournamentId}
        group={selectedGroup}
        playersById={this.state.tournament.playersById}
        isOpen={this.state.selectedGroupIndex !== null}
        onRequestClose={this._closeGroupModal}
      />
    );
  };

  render() {
    return (
      <div css={styles.container}>
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <StyledButton onClick={this._downloadCSV}>
          {"Download Results CSV"}
        </StyledButton>
        <Table
          className="max-w-4xl mt-4"
          definition={tableDef}
          data={this.state.groups}
          onClick={this._goToGroup}
          loading={this.state.loading}
        />
        {this._renderModal()}
      </div>
    );
  }
}

export default withRouter(TournamentGroupsScreen);
