import * as tournamentPlayers from "../lib/api/tournamentPlayers";
import * as usattPlayers from "../lib/api/usattPlayers";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import Modal from "./Modal";
import PropTypes from "prop-types";
import React from "react";
import StyledButton from "./StyledButton";
import TextInput from "./TextInput";
import { getAgeToday, stringToDate } from "../lib/dateUtil";

const inputs = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
};

const strings = {
  title: "Add Player",
};

export default class AddTournamentPlayerModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    tournamentId: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func,
    onUpdatedPlayers: PropTypes.func,
  };

  static defaultProps = {
    isOpen: true,
  };

  state = {
    selectedPlayerOption: null,
    inputs: {
      [inputs.FIRST_NAME]: "",
      [inputs.LAST_NAME]: "",
    },
  };

  _getOptions = searchText => {
    return usattPlayers
      .getPlayers(searchText, 0, 40, this.props.tournamentId)
      .then(players =>
        players.map(p => {
          const age = getAgeToday(stringToDate(p.birthDate));
          return {
            value: p,
            label: `${p.fullName} (${p.usattRating}) (Age: ${age}) ${p.state}`,
          };
        })
      );
  };

  _debouncedGetOptions = debounce((search, callback) => {
    this._getOptions(search)
      .then(results => callback(results))
      .catch(error => callback(null));
  }, 400);

  _addPlayerToTournament = () => {
    if (
      !this.state.selectedPlayerOption &&
      !(
        this.state.inputs[inputs.FIRST_NAME] ||
        this.state.inputs[inputs.LAST_NAME]
      )
    ) {
      return;
    }

    if (this.state.selectedPlayerOption) {
      const player = this.state.selectedPlayerOption.value;
      return tournamentPlayers
        .newPlayer(this.props.tournamentId, player)
        .then(updatedPlayers => {
          this.setState({ selectedPlayerOption: null });
          if (this.props.onUpdatedPlayers) {
            this.props.onUpdatedPlayers(updatedPlayers);
          }
        });
    }

    // Manual input player
    const player = {
      firstName: this.state.inputs[inputs.FIRST_NAME],
      lastName: this.state.inputs[inputs.LAST_NAME],
      usattRating: 0,
    };
    return tournamentPlayers
      .newPlayer(this.props.tournamentId, player)
      .then(updatedPlayers => {
        this.setState(prevState => ({
          inputs: {
            ...prevState.inputs,
            [inputs.FIRST_NAME]: "",
            [inputs.LAST_NAME]: "",
          },
        }));
        if (this.props.onUpdatedPlayers) {
          this.props.onUpdatedPlayers(updatedPlayers);
        }
      });
  };

  _onRequestClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  _onChangeSelectedPlayer = option => {
    this.setState({ selectedPlayerOption: option });
  };

  _onChangeInput = event => {
    const key = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [key]: value,
      },
    }));
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this._onRequestClose}
        height={288}
        width={460}
        title={strings.title}
      >
        <div className="pt-3 flex flex-col items-stretch">
          <AsyncSelect
            cacheOptions
            defaultOptions
            isClearable={true}
            loadOptions={this._debouncedGetOptions}
            menuPortalTarget={document.body}
            value={this.state.selectedPlayerOption}
            onChange={this._onChangeSelectedPlayer}
          />
          <div className="self-center">OR</div>
          <TextInput
            name={inputs.FIRST_NAME}
            value={this.state.inputs[inputs.FIRST_NAME]}
            onChange={this._onChangeInput}
            placeholder={"First Name"}
          />
          <TextInput
            name={inputs.LAST_NAME}
            value={this.state.inputs[inputs.LAST_NAME]}
            onChange={this._onChangeInput}
            placeholder={"Last Name"}
          />
          <StyledButton
            className="self-center"
            onClick={this._addPlayerToTournament}
          >
            Add
          </StyledButton>
        </div>
      </Modal>
    );
  }
}
