/** @jsx jsx */
import * as routeHelpers from "../../lib/routeHelpers";
import * as tournamentEvents from "../../lib/api/tournamentEvents";
import * as tournamentPlayers from "../../lib/api/tournamentPlayers";
import Card from "../Card";
import PropTypes from "prop-types";
import React from "react";
import RRPlayersList from "../RRPlayersList";
import Select from "react-select";
import SEPlayersList from "../SEPlayersList";
import StyledButton from "../StyledButton";
import TextInput from "../TextInput";
import { jsx } from "@emotion/core";
import { Prompt, withRouter } from "react-router-dom";

const styles = {
  container: {
    padding: 24,
  },
  card: {
    padding: 24,
    marginBottom: 16,
    maxWidth: 800,
  },
  inputContainer: {
    paddingTop: 4,
    paddingBottom: 8,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  textInput: {
    width: 400,
  },
  playersListCard: {
    marginTop: 12,
    paddingTop: 8,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
    maxWidth: 400,
  },
};

const typeOptions = [
  { value: "ROUND_ROBIN", label: "Round Robin" },
  { value: "SINGLE_ELIMINATION", label: "Single Elimination" },
];

const strings = {
  newTitle: "New Event",
};

const inputs = {
  NAME: "name",
  MAX_GAMES: "maxGames",
  TYPE: "type",
  NUM_PER_GROUP: "numPerGroup",
};

class TournamentEventDetailsScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    event: null,
    inputs: {
      [inputs.NAME]: "",
      [inputs.MAX_GAMES]: "5",
      [inputs.TYPE]: "",
      [inputs.NUM_PER_GROUP]: "4",
    },
    players: [],
    dirty: false,
    playerOptions: [],
    selectedPlayerOption: null,
    previewGroups: [],
    allPlayersById: {},
  };

  componentDidMount() {
    const tournamentId = this.props.match.params.id;
    const eventId = this.props.match.params.eventId;
    const isNew = eventId === "new";
    if (!isNew) {
      tournamentEvents
        .getEvent(tournamentId, eventId)
        .then(this._updateStateEvent);
    }
    this._getTournamentPlayers();
  }

  _updateStateEvent = event => {
    this.setState({
      event,
      inputs: {
        [inputs.NAME]: event.name || "",
        [inputs.MAX_GAMES]: event.maxGames ? String(event.maxGames) : "5",
        [inputs.NUM_PER_GROUP]: event.numPerGroup
          ? String(event.numPerGroup)
          : "",
        [inputs.TYPE]: event.type || null,
      },
      players:
        event.players ||
        (event.type === "SINGLE_ELIMINATION" ? new Array(8) : []),
      dirty: false,
    });
    this._updatePreviewGroups(event.players, event.numPerGroup);
  };

  _getTournamentPlayers = () => {
    const tournamentId = this.props.match.params.id;
    this.setState({ loading: true });
    tournamentPlayers
      .getPlayers(tournamentId, this.state.searchValue)
      .then(players => {
        const allPlayersById = {};
        players.forEach(p => (allPlayersById[p.id] = p));
        this.setState({
          allPlayersById,
          playerOptions: players.map(p => ({
            value: p,
            label: `${p.firstName} ${p.lastName} (${p.usattRating})`,
          })),
          loading: false,
        });
      });
  };

  _saveEdits = () => {
    this.setState({ loading: true });
    const tournamentId = this.props.match.params.id;
    const eventId = this.props.match.params.eventId;
    const isNew = eventId === "new";
    if (isNew) {
      const newEvent = {
        name: this.state.inputs[inputs.NAME],
        maxGames: parseInt(this.state.inputs[inputs.MAX_GAMES]),
        numPerGroup: this.state.inputs[inputs.NUM_PER_GROUP]
          ? parseInt(this.state.inputs[inputs.NUM_PER_GROUP])
          : null,
        type: this.state.inputs[inputs.TYPE],
        playerIds: this.state.players.map(p => (p ? p.id : null)),
        // TODO: Other fields
      };
      return tournamentEvents
        .newEvent(tournamentId, newEvent)
        .then(event => {
          this._updateStateEvent(event);
          this.props.history.push(
            routeHelpers.tournamentEvent(tournamentId, event.id)
          );
        })
        .then(() => this.setState({ loading: false }));
    } else {
      const updatedEvent = {
        id: eventId,
        name: this.state.inputs[inputs.NAME],
        maxGames: parseInt(this.state.inputs[inputs.MAX_GAMES]),
        numPerGroup: this.state.inputs[inputs.NUM_PER_GROUP]
          ? parseInt(this.state.inputs[inputs.NUM_PER_GROUP])
          : null,
        type: this.state.inputs[inputs.TYPE],
        playerIds: this.state.players.map(p => (p ? p.id : null)),
        // TODO: Other fields
      };
      return tournamentEvents
        .editEvent(tournamentId, updatedEvent)
        .then(this._updateStateEvent)
        .then(() => this.setState({ loading: false }));
    }
  };

  _onClickDelete = () => {
    const didConfirm = window.confirm(
      "Are you sure you want to delete? This cannot be undone."
    );
    if (!didConfirm) {
      return;
    }

    this.setState({ loading: true });
    const tournamentId = this.props.match.params.id;
    const eventId = this.props.match.params.eventId;
    return tournamentEvents
      .deleteEvent(tournamentId, eventId)
      .then(() =>
        this.props.history.push(routeHelpers.tournamentEvents(tournamentId))
      );
  };

  _updatePreviewGroups = (players, numPerGroup) => {
    if (!players) {
      return;
    }
    const tournamentId = this.props.match.params.id;
    const eventId = this.props.match.params.eventId;
    const playerIds = players.map(p => (p ? p.id : null));
    return tournamentEvents
      .previewGenerateGroups(tournamentId, eventId, playerIds, numPerGroup)
      .then(groups => {
        this.setState({ previewGroups: groups || [] });
      });
  };

  _generateGroups = () => {
    if (this.state.event.groups && this.state.event.groups.length > 0) {
      const didConfirm = window.confirm(
        "This will override the existing groups for this event. Are you sure?"
      );
      if (!didConfirm) {
        return;
      }
    }
    const tournamentId = this.props.match.params.id;
    const eventId = this.props.match.params.eventId;
    return tournamentEvents
      .generateGroups(tournamentId, eventId)
      .then(() =>
        this.props.history.push(routeHelpers.tournamentEvents(tournamentId))
      );
  };

  _onChangeInput = event => {
    const key = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [key]: value,
      },
      dirty: true,
    }));
    if (key === inputs.NUM_PER_GROUP) {
      this._updatePreviewGroups(this.state.players, parseInt(value));
    }
  };

  _handleChangeType = selectedOption => {
    if (this.state.players && this.state.players.some(p => !!p)) {
      const didConfirm = window.confirm(
        "Are you sure you want to change event type? This will clear your players list."
      );
      if (!didConfirm) {
        return;
      }
    }
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [inputs.TYPE]: selectedOption.value,
      },
      players: selectedOption.value === "ROUND_ROBIN" ? [] : new Array(8),
      dirty: true,
    }));
  };

  _renderInput = (label, key) => {
    return (
      <div css={styles.inputContainer}>
        <div css={styles.label}>{label}</div>
        <TextInput
          cssStyle={styles.textInput}
          name={key}
          value={this.state.inputs[key]}
          onChange={this._onChangeInput}
        />
      </div>
    );
  };

  _onChangePlayers = players => {
    this.setState({ players, dirty: true });
    this._updatePreviewGroups(
      players,
      parseInt(this.state.inputs[inputs.NUM_PER_GROUP])
    );
  };

  render() {
    const isNew = this.props.match.params.eventId === "new";
    if (!isNew && !this.state.event) {
      return null;
    }

    const title =
      this.props.match.params.eventId === "new"
        ? strings.newTitle
        : `Event - ${this.state.event.name}`;

    return (
      <div css={styles.container}>
        <Prompt
          when={this.state.dirty}
          message={"You have unsaved changes. Are you sure you want to leave?"}
        />

        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {title}
        </div>
        <Card cssStyle={styles.card}>
          <StyledButton
            className="mr-2"
            disabled={!this.state.dirty}
            onClick={this._saveEdits}
          >
            Save
          </StyledButton>
          {!this.state.dirty && this.state.players.length > 0 ? (
            <StyledButton className="mr-2" onClick={this._generateGroups}>
              {`Generate ${
                this.state.inputs[inputs.TYPE] === "ROUND_ROBIN"
                  ? "Groups"
                  : "Draw"
              }`}
            </StyledButton>
          ) : null}
          {!isNew && (
            <StyledButton onClick={this._onClickDelete}>Delete</StyledButton>
          )}
          {this._renderInput("Name", inputs.NAME)}
          {this._renderInput("Max Games", inputs.MAX_GAMES)}
          <div css={styles.inputContainer}>
            <div css={styles.label}>{"Type"}</div>
            <Select
              css={styles.textInput}
              value={typeOptions.find(
                to => to.value === this.state.inputs[inputs.TYPE]
              )}
              onChange={this._handleChangeType}
              options={typeOptions}
            />
          </div>
          {this.state.inputs[inputs.TYPE] === "ROUND_ROBIN"
            ? this._renderInput("# per Group", inputs.NUM_PER_GROUP)
            : null}
          {this.state.inputs[inputs.TYPE] === "SINGLE_ELIMINATION" ? (
            <div css={styles.inputContainer}>
              <div css={styles.label}>{"# of Players"}</div>
              <Select
                css={styles.textInput}
                value={
                  this.state.players.length >= 2
                    ? {
                        value: this.state.players.length,
                        label: `${this.state.players.length}`,
                      }
                    : null
                }
                onChange={selection =>
                  this.setState({
                    players: new Array(selection.value),
                    dirty: true,
                  })
                }
                options={[
                  { value: 2, label: "2" },
                  { value: 4, label: "4" },
                  { value: 8, label: "8" },
                ]}
              />
            </div>
          ) : null}
        </Card>
        {!isNew ? (
          <div>
            <Card cssStyle={styles.playersListCard}>
              {this.state.inputs[inputs.TYPE] === "SINGLE_ELIMINATION" ? (
                <SEPlayersList
                  numPlayers={this.state.players.length}
                  playerOptions={this.state.playerOptions}
                  players={this.state.players}
                  onChange={this._onChangePlayers}
                />
              ) : (
                <RRPlayersList
                  playerOptions={this.state.playerOptions}
                  players={this.state.players}
                  onChange={this._onChangePlayers}
                />
              )}
            </Card>
            <div className="mt-4 font-bold text-lg">Group Preview</div>
            <div className="mt-2 flex flex-row gap-4">
              {this.state.previewGroups.map((group, i) => (
                <Card key={i} className="flex-1 max-w-sm p-3">
                  <div className="font-bold underline">{group.name}</div>
                  <div>{`Num Matches: ${
                    group.matches.filter(m => m.status !== "BYE").length
                  }`}</div>
                  {group.playerIds
                    ? group.playerIds.map((pId, pIndex) => {
                        const p = this.state.allPlayersById[pId];
                        if (!p) {
                          return null;
                        }
                        return (
                          <div key={pId}>{`${String.fromCharCode(
                            65 + pIndex
                          )}. ${p.firstName} ${p.lastName}`}</div>
                        );
                      })
                    : null}
                </Card>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(TournamentEventDetailsScreen);
