/** @jsx jsx */
import * as tournaments from "../../lib/api/tournaments";
import Card from "../Card";
import PropTypes from "prop-types";
import React from "react";
import Table from "../Table";
import { jsx } from "@emotion/core";
import { withRouter } from "react-router-dom";

const tableDef = [
  { title: "User", key: "account", renderer: account => account.email },
  { title: "Admin", key: "admin", renderer: admin => (admin ? "Yes" : "No") },
];

const styles = {
  container: {
    padding: 24,
  },
  textInput: {
    width: 200,
    marginBottom: 12,
  },
  tableCard: {
    maxWidth: 800,
  },
  table: {
    padding: 16,
  },
};

const strings = {
  title: "Access Management",
};

class TournamentPlayersScreen extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    tournament: null,
    loading: true,
  };

  componentDidMount() {
    this._getTournament();
  }

  _getTournament = () => {
    const tournamentId = this.props.match.params.id;
    this.setState({ loading: true });
    tournaments
      .getTournament(tournamentId)
      .then(this._updateStateTournament)
      .then(() => this.setState({ loading: false }));
  };

  _updateStateTournament = tournament => {
    this.setState({ tournament });
  };

  render() {
    if (this.state.loading || !this.state.tournament) {
      return null;
    }

    return (
      <div css={styles.container}>
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <Card cssStyle={styles.tableCard}>
          <Table
            cssStyle={styles.table}
            definition={tableDef}
            data={this.state.tournament.accessUsers}
            loading={this.state.loading}
            onClick={this._goToTournamentPlayer}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(TournamentPlayersScreen);
