import "react-datepicker/dist/react-datepicker.css";
import * as account from "../../lib/api/account";
import * as routeHelpers from "../../lib/routeHelpers";
import * as routeNames from "../../constants/routeNames";
import * as tournaments from "../../lib/api/tournaments";
import Card from "../Card";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import React from "react";
import Select, { ValueType } from "react-select";
import StyledButton from "../StyledButton";
import TextInput from "../TextInput";
import { withRouter, RouteComponentProps } from "react-router-dom";

const strings = {
  newTitle: "New Tournament",
};

const inputs = {
  NAME: "name" as const,
  DATE: "date" as const,
  LOCATION: "location" as const,
  CLUB: "club" as const,
  CITY: "city" as const,
  STATE: "state" as const,
  PRIMARY_CONTACT_NAME: "primaryContactName" as const,
};

class TournamentDetailsScreen extends React.Component<
  RouteComponentProps<{ id: string }>,
  {
    tournament: any;
    inputs: {
      [inputs.NAME]: string;
      [inputs.DATE]: Date | null | undefined;
      [inputs.LOCATION]: string;
      [inputs.CLUB]: ValueType<{
        label: string;
        value: string;
      }> | null;
      [inputs.CITY]: string;
      [inputs.STATE]: string;
      [inputs.PRIMARY_CONTACT_NAME]: string;
    };
    clubs: { id: string; name: string }[];
    loading: boolean;
  }
> {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.any,
  };

  state = {
    tournament: null as any,
    inputs: {
      [inputs.NAME]: "",
      [inputs.DATE]: null,
      [inputs.LOCATION]: "",
      [inputs.CLUB]: null as ValueType<{
        label: string;
        value: string;
      }> | null,
      [inputs.CITY]: "",
      [inputs.STATE]: "",
      [inputs.PRIMARY_CONTACT_NAME]: "",
    },
    clubs: [] as { id: string; name: string }[],
    loading: false,
  };

  componentDidMount() {
    const tournamentId = this.props.match.params.id;
    const isNew = tournamentId === "new";
    if (!isNew) {
      tournaments.getTournament(tournamentId).then(this._updateStateTournament);
    }
    // For getting available clubs
    account.me().then(account => {
      this.setState({ clubs: account.clubs });
    });
  }

  _updateStateTournament = (tournament: {
    name: string;
    date: Date | null;
    location: string;
    club: { id: string; name: string };
    city: string;
    state: string;
    primaryContactName: string;
  }) => {
    this.setState({
      tournament,
      inputs: {
        [inputs.NAME]: tournament.name || "",
        [inputs.DATE]: tournament.date || null,
        [inputs.LOCATION]: tournament.location || "",
        [inputs.CLUB]: tournament.club
          ? {
              label: tournament.club.name,
              value: tournament.club.id,
            }
          : null,
        [inputs.CITY]: tournament.city || "",
        [inputs.STATE]: tournament.state || "",
        [inputs.PRIMARY_CONTACT_NAME]: tournament.primaryContactName || "",
      },
    });
  };

  _saveEdits = () => {
    this.setState({ loading: true });
    const isNew = this.props.match.params.id === "new";
    if (isNew) {
      const newTournament = {
        name: this.state.inputs[inputs.NAME],
        date: this.state.inputs[inputs.DATE],
        location: this.state.inputs[inputs.LOCATION],
        // @ts-ignore
        clubId: this.state.inputs[inputs.CLUB]?.value || null,
        city: this.state.inputs[inputs.CITY],
        state: this.state.inputs[inputs.STATE],
        primaryContactName: this.state.inputs[inputs.PRIMARY_CONTACT_NAME],
      };
      return tournaments
        .newTournament(newTournament)
        .then(tournament => {
          this._updateStateTournament(tournament);
          this.props.history.push(routeHelpers.tournament(tournament.id));
        })
        .then(() => this.setState({ loading: false }));
    } else {
      const tournamentId = this.props.match.params.id;
      const updatedTournament = {
        id: tournamentId,
        name: this.state.inputs[inputs.NAME],
        date: this.state.inputs[inputs.DATE],
        location: this.state.inputs[inputs.LOCATION],
        // @ts-ignore
        clubId: this.state.inputs[inputs.CLUB]?.value || null,
        city: this.state.inputs[inputs.CITY],
        state: this.state.inputs[inputs.STATE],
        primaryContactName: this.state.inputs[inputs.PRIMARY_CONTACT_NAME],
      };
      return tournaments
        .editTournament(updatedTournament)
        .then(this._updateStateTournament)
        .then(() => this.setState({ loading: false }));
    }
  };

  _onClickDelete = () => {
    const didConfirm = window.confirm(
      "Are you sure you want to delete? This cannot be undone."
    );
    if (!didConfirm) {
      return;
    }

    this.setState({ loading: true });
    const tournamentId = this.props.match.params.id;
    return tournaments
      .deleteTournament(tournamentId)
      .then(() => this.props.history.push(routeNames.TOURNAMENTS));
  };

  _onChangeInput: React.FormEventHandler<HTMLInputElement> = event => {
    // @ts-ignore
    const key = event.target.name;
    // @ts-ignore
    const value = event.target.value;
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [key]: value,
      },
    }));
  };

  _renderInput = (label: string, key: string) => {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor={key}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <TextInput
            className="max-w-lg block w-full sm:max-w-xs sm:text-sm"
            type="text"
            name={key}
            id={key}
            // @ts-ignore
            value={this.state.inputs[key] || ""}
            onChange={this._onChangeInput}
          />
        </div>
      </div>
    );
  };

  _renderDateInput = (label: any, key: any) => {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor={key}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <DatePicker
            id={key}
            name={key}
            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
            // @ts-ignore
            selected={this.state.inputs[key]}
            onChange={date => {
              this.setState(prevState => ({
                inputs: {
                  ...prevState.inputs,
                  [key]: date,
                },
              }));
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const isNew = this.props.match.params.id === "new";
    if (!isNew && !this.state.tournament) {
      return null;
    }

    const title =
      this.props.match.params.id === "new"
        ? strings.newTitle
        : this.state.tournament.name;

    return (
      <div className="p-6">
        {/* @ts-ignore */}
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {title}
        </div>
        <Card className="p-8">
          <StyledButton onClick={this._saveEdits} className="mr-2">
            Save
          </StyledButton>
          {!isNew && (
            <StyledButton onClick={this._onClickDelete}>Delete</StyledButton>
          )}
          <div className="pt-4 space-y-6 sm:pt-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              {this._renderInput("Name", inputs.NAME)}
              {this._renderDateInput("Date", inputs.DATE)}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor={inputs.CLUB}
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Club
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  {this.state.clubs.length >= 2 || isNew ? (
                    <Select
                      className="max-w-lg block w-full sm:max-w-xs sm:text-sm"
                      id={inputs.CLUB}
                      value={this.state.inputs[inputs.CLUB]}
                      options={this.state.clubs.map(club => ({
                        label: club.name,
                        value: club.id,
                      }))}
                      onChange={option =>
                        this.setState(prevState => ({
                          inputs: {
                            ...prevState.inputs,
                            [inputs.CLUB]: option,
                          },
                        }))
                      }
                    />
                  ) : (
                    // @ts-ignore
                    <div>{this.state.inputs[inputs.CLUB]?.label}</div>
                  )}
                </div>
              </div>
              {this._renderInput("Location", inputs.LOCATION)}
              {this._renderInput("City", inputs.CITY)}
              {this._renderInput("State", inputs.STATE)}
              {this._renderInput(
                "Primary Contact Name",
                inputs.PRIMARY_CONTACT_NAME
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(TournamentDetailsScreen);
