import classNames from "classnames";
import Loader from "../loaders/EllipsisLoader";
import React, { Component } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

type SortOrder = "asc" | "desc" | undefined;

function desc(a: any, b: any, orderBy: string | null) {
  if (!orderBy) {
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array: any[], cmp: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order: SortOrder, orderBy: string | null) {
  return order === "desc"
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
}

const strings = {
  emptyMessage: "No records found.",
};

type TableProps<T extends Record<string, any>> = {
  cssStyle?: any;
  className?: string;
  bodyClassName?: string;
  definition: {
    title: string;
    key: string;
    renderer?: (value: T[keyof T], item: T) => React.ReactNode;
  }[];
  data: T[];
  onClick?: (rowId: string, index: number) => void;
  loading: boolean;
};

export default class Table<T> extends Component<TableProps<T>> {
  static defaultProps = {
    loading: false,
  };

  state: { order: SortOrder; orderBy: string | null } = {
    order: undefined,
    orderBy: null,
  };

  _onClickRow = (rowId: string, index: number) => {
    if (this.props.onClick) {
      this.props.onClick(rowId, index);
    }
  };

  _sortBy = (property: string) => () => {
    let orderBy: string | null = property;
    let order: "asc" | "desc" | undefined = "asc";
    if (this.state.orderBy === property) {
      if (this.state.order === "asc") {
        order = "desc";
      } else if (this.state.order === "desc") {
        order = undefined;
        orderBy = null;
      }
    }
    this.setState({ order, orderBy });
  };

  _getSortIcon = (active: boolean, order: SortOrder) => {
    if (!active) {
      return null;
    }
    if (order === "asc") {
      return <FaArrowDown />;
    }
    if (order === "desc") {
      return <FaArrowUp />;
    }
    return null;
  };

  _renderHeader = () => {
    const { order, orderBy } = this.state;
    return this.props.definition.map((def, index) => {
      if (index === 0) {
        return (
          <td key={index} className="px-6 py-3 text-left tracking-wider">
            <div
              onClick={this._sortBy(def.key)}
              className="flex flex-row items-center"
            >
              {def.title}
              {this._getSortIcon(orderBy === def.key, order)}
            </div>
          </td>
        );
      } else {
        return (
          <td key={index} className="px-6 py-3 text-left tracking-wider">
            <div
              onClick={this._sortBy(def.key)}
              className="flex flex-row items-center"
            >
              {def.title}
              {this._getSortIcon(orderBy === def.key, order)}
            </div>
          </td>
        );
      }
    });
  };

  _renderRow = (row: any) => {
    return this.props.definition.map((def, index) => {
      const content = def.renderer
        ? def.renderer(row[def.key], row)
        : row[def.key];
      if (index === 0) {
        return (
          <td key={index} className="px-6 py-4 whitespace-nowrap">
            {content}
          </td>
        );
      } else {
        return (
          <td key={index} className="px-6 py-4 whitespace-nowrap">
            {content}
          </td>
        );
      }
    });
  };

  _renderBody = () => {
    const colSpan = this.props.definition ? this.props.definition.length : 1;

    if (this.props.loading) {
      return (
        <tr>
          <td colSpan={colSpan}>
            <div className="flex justify-center">
              <Loader />
            </div>
          </td>
        </tr>
      );
    }

    if (!this.props.data || this.props.data.length === 0) {
      return (
        <tr>
          <td className="p-4 bg-white rounded-md" colSpan={colSpan}>
            {strings.emptyMessage}
          </td>
        </tr>
      );
    }

    const { order, orderBy } = this.state;

    return stableSort(this.props.data, getSorting(order, orderBy)).map(
      (row, index) => (
        <tr
          key={row.id || index}
          className={classNames(
            index % 2 === 0 ? "bg-white" : "bg-gray-50",
            "hover:bg-gray-100",
            this.props.onClick && "cursor-pointer"
          )}
          onClick={() => this._onClickRow(row.id, index)}
        >
          {this._renderRow(row)}
        </tr>
      )
    );
  };

  render() {
    return (
      <div
        css={this.props.cssStyle}
        className={classNames(
          "text-sm text-gray-700",
          "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg",
          this.props.className
        )}
      >
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
            <tr>{this._renderHeader()}</tr>
          </thead>
          <tbody className={this.props.bodyClassName}>
            {this._renderBody()}
          </tbody>
        </table>
      </div>
    );
  }
}
