import * as routeNames from "../constants/routeNames";
import React from "react";
import withFirebaseAuth from "../lib/withFirebaseAuth";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

const PrivateRoute = ({ path, children, user, ...rest }) => {
  if (user === null) {
    return <Redirect push to={routeNames.LOGIN} />;
  }
  return (
    <Route path={path} {...rest}>
      {!!user ? children : null}
    </Route>
  );
};

export default withFirebaseAuth(PrivateRoute);
