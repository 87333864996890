import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import TournamentBracketBase from "./TournamentBracketBase";

const seedPlacements = {
  2: [1, 2],
  4: [1, 3, 3, 2],
  8: [1, 5, 5, 3, 3, 5, 5, 2],
  16: [1, 9, 9, 5, 5, 9, 9, 3, 3, 9, 9, 5, 5, 9, 9, 2],
};

function SEPlayersList(props) {
  const numberOfMatches = Math.floor(props.numPlayers / 2);

  const _addPlayer = (player, index) => {
    const nextPlayers = [...props.players];
    nextPlayers[index] = player;
    props.onChange && props.onChange(nextPlayers);
  };

  const _removePlayer = index => {
    const nextPlayers = [...props.players];
    nextPlayers[index] = null;
    props.onChange && props.onChange(nextPlayers);
  };

  const _onChangeSelectedPlayer = (option, playerIndex) => {
    if (option === null) {
      _removePlayer(playerIndex);
      return;
    }
    _addPlayer(option.value, playerIndex);
  };

  const seedPlacement = seedPlacements[numberOfMatches * 2];
  return (
    <TournamentBracketBase
      rounds={[
        [...Array(numberOfMatches).keys()].map(matchIndex => {
          return {
            player1Children: (
              <div className="flex flex-row items-center">
                <div className="mr-1">{seedPlacement[matchIndex * 2 + 0]}</div>
                <Select
                  className="flex-1"
                  placeholder="Add player..."
                  isClearable={true}
                  options={props.playerOptions.filter(
                    po =>
                      !props.players
                        .filter(p => !!p)
                        .some(p => po.value.id === p.id)
                  )}
                  menuPortalTarget={document.body}
                  value={
                    matchIndex * 2 + 0 < props.players.length &&
                    props.players[matchIndex * 2 + 0]
                      ? props.playerOptions.find(
                          po =>
                            po.value.id === props.players[matchIndex * 2 + 0].id
                        )
                      : null
                  }
                  onChange={o => _onChangeSelectedPlayer(o, matchIndex * 2 + 0)}
                />
              </div>
            ),
            player2Children: (
              <div className="flex flex-row items-center">
                <div className="mr-1">{seedPlacement[matchIndex * 2 + 1]}</div>
                <Select
                  className="flex-1"
                  placeholder="Add player..."
                  isClearable={true}
                  options={props.playerOptions.filter(
                    po =>
                      !props.players
                        .filter(p => !!p)
                        .some(p => po.value.id === p.id)
                  )}
                  menuPortalTarget={document.body}
                  value={
                    matchIndex * 2 + 1 < props.players.length &&
                    props.players[matchIndex * 2 + 1]
                      ? props.playerOptions.find(
                          po =>
                            po.value.id === props.players[matchIndex * 2 + 1].id
                        )
                      : null
                  }
                  onChange={o => _onChangeSelectedPlayer(o, matchIndex * 2 + 1)}
                />
              </div>
            ),
          };
        }),
      ]}
    />
  );
}

SEPlayersList.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object),
  playerOptions: PropTypes.arrayOf(PropTypes.object),
  numPlayers: PropTypes.number,
  onChange: PropTypes.func,
};

export default SEPlayersList;
