/** @jsx jsx */
import * as usattPlayers from "../../lib/api/usattPlayers";
import Card from "../Card";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React from "react";
import Table from "../Table";
import TextInput from "../TextInput";
import { jsx } from "@emotion/core";
import { withRouter } from "react-router-dom";

const tableDef = [
  { title: "Name", key: "fullName" },
  { title: "Rating", key: "usattRating" },
  { title: "USATT #", key: "usattId" },
];

const styles = {
  container: {
    padding: 24,
  },
  table: {
    padding: 16,
  },
};

const strings = {
  title: "Player Search",
};

class UsattPlayersScreen extends React.Component {
  static propTypes = {
    history: PropTypes.any,
  };

  state = {
    players: [],
    loading: true,
    searchValue: "",
  };

  componentDidMount() {
    this._debouncedGetPlayers = debounce(this._getPlayers, 400);
    this._getPlayers();
  }

  _getPlayers = () => {
    this.setState({ loading: true });
    usattPlayers
      .getPlayers(this.state.searchValue)
      .then(players => this.setState({ players, loading: false }));
  };

  _onChangeSearchValue = event => {
    const value = event.target.value;
    this.setState({ searchValue: value });
    this._debouncedGetPlayers();
  };

  render() {
    return (
      <div css={styles.container}>
        <div className="text-4xl pl-1 mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {strings.title}
        </div>
        <TextInput
          className="mb-3 w-60"
          value={this.state.searchValue}
          onChange={this._onChangeSearchValue}
          placeholder="Search by name..."
        />
        <Card>
          <Table
            cssStyle={styles.table}
            definition={tableDef}
            data={this.state.players}
            loading={this.state.loading}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(UsattPlayersScreen);
