export const tournament = tournamentId => `/tournaments/${tournamentId}`;
export const tournamentOverview = tournamentId =>
  `/tournaments/${tournamentId}/overview`;
export const tournamentEvents = tournamentId =>
  `/tournaments/${tournamentId}/events`;
export const tournamentEvent = (tournamentId, eventId) =>
  `/tournaments/${tournamentId}/events/${eventId}`;
export const tournamentPlayers = tournamentId =>
  `/tournaments/${tournamentId}/players`;
export const tournamentPlayer = (tournamentId, playerId) =>
  `/tournaments/${tournamentId}/players/${playerId}`;
export const tournamentAccess = tournamentId =>
  `/tournaments/${tournamentId}/access`;
export const tournamentDevices = tournamentId =>
  `/tournaments/${tournamentId}/devices`;
export const tournamentScores = tournamentId =>
  `/tournaments/${tournamentId}/scores`;
