import * as routeHelpers from "../../lib/routeHelpers";
import NavMenuItem from "./NavMenuItem";
import React from "react";
import { useLocation } from "react-router-dom";
import withFirebaseAuth, {
  FirebaseAuthProps,
} from "../../lib/withFirebaseAuth";
import {
  FaChartBar,
  FaListAlt,
  FaTabletAlt,
  FaTableTennis,
  FaUsers,
} from "react-icons/fa";

function getTournamentId(path: string) {
  if (path.startsWith("/")) {
    path = path.slice(1, path.length);
  }
  const pathParts = path.split("/");
  if (pathParts.length < 2 || pathParts[0] !== "tournaments") {
    return null;
  }
  return pathParts[1];
}

function NavMenu(props: FirebaseAuthProps) {
  const location = useLocation();
  const isInTournament =
    location.pathname.startsWith("/tournaments") &&
    location.pathname !== "/tournaments";
  const isNewTournament = location.pathname === "/tournaments/new";

  if (!isInTournament) {
    return null;
  }

  const tournamentItems = (
    <React.Fragment>
      <NavMenuItem
        label="Info"
        to={routeHelpers.tournament(getTournamentId(location.pathname))}
        exact
        icon={<FaTableTennis />}
      />
      {!isNewTournament && (
        <React.Fragment>
          <NavMenuItem
            label="Overview"
            to={routeHelpers.tournamentOverview(
              getTournamentId(location.pathname)
            )}
            icon={<FaChartBar />}
          />
          <NavMenuItem
            label="Events"
            to={routeHelpers.tournamentEvents(
              getTournamentId(location.pathname)
            )}
            icon={<FaListAlt />}
          />
          <NavMenuItem
            label="Players"
            to={routeHelpers.tournamentPlayers(
              getTournamentId(location.pathname)
            )}
            icon={<FaUsers />}
          />
          {/* TODO: Uncomment after we enable access management*/}
          {/*<NavMenuItem
            label="Manage Access"
            to={routeHelpers.tournamentAccess(
              getTournamentId(location.pathname)
            )}
          />*/}
          <NavMenuItem
            label="Devices"
            to={routeHelpers.tournamentDevices(
              getTournamentId(location.pathname)
            )}
            icon={<FaTabletAlt />}
          />
          <NavMenuItem
            label="Score Input"
            to={routeHelpers.tournamentScores(
              getTournamentId(location.pathname)
            )}
            icon={<FaTabletAlt />}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );

  // TODO: Loading doesn't seem to be working correctly.
  // if (
  //   !props.loading &&
  //   !props.user &&
  //   window.location.pathname !== routeNames.HOME &&
  //   window.location.pathname !== routeNames.LOGIN
  // ) {
  //   return <Redirect to={routeNames.HOME} />;
  // }

  return (
    <div className="sm:w-38 md:w-56 flex flex-col px-3">
      <div className="hidden pt-4 px-2 md:block">
        {props.user && (
          <div className="pb-1">{`Logged in as ${props.user.email}`}</div>
        )}
      </div>
      <div className="pt-4">{!!props.user && tournamentItems}</div>
    </div>
  );
}

export default withFirebaseAuth(NavMenu);
