export function isGameCompleted(game) {
  const { score1, score2 } = game;
  if (score1 === null || score2 === null) {
    return false;
  }
  const difference = Math.abs(score1 - score2);
  if ((score1 > 11 || score2 > 11) && difference === 2) {
    return true;
  }
  if (
    difference >= 2 &&
    ((score1 === 11 && score2 < 11) || (score2 === 11 && score1 < 11))
  ) {
    return true;
  }
  return false;
}

export function isMatchCompleted(games, maxNumGames) {
  games.forEach(game => {
    if (!isGameCompleted(game)) {
      return false;
    }
  });
  const numGamesToWin = Math.ceil(maxNumGames / 2.0);
  const { player1GameWinCount, player2GameWinCount } = gameScores(games);
  if (
    player1GameWinCount >= numGamesToWin ||
    player2GameWinCount >= numGamesToWin
  ) {
    return true;
  }
  return false;
}

export function gameScores(games) {
  let player1GameWinCount = 0;
  let player2GameWinCount = 0;
  let player1PointsWon = 0;
  let player2PointsWon = 0;
  for (let i = games.length - 1; i >= 0; i--) {
    if (games[i].score1 === null || games[i].score2 === null) {
      continue;
    }
    if (games[i].score1 > games[i].score2) {
      player1GameWinCount += 1;
    } else if (games[i].score1 < games[i].score2) {
      player2GameWinCount += 1;
    }
    player1PointsWon += games[i].score1;
    player2PointsWon += games[i].score2;
  }
  return {
    player1GameWinCount,
    player2GameWinCount,
    player1PointsWon,
    player2PointsWon,
  };
}

// Returns match winner and match loser
export function getMatchResults(match, maxGames) {
  match.games.forEach(game => {
    if (
      !isGameCompleted(game) &&
      game.score1 !== null &&
      game.score2 !== null
    ) {
      return null;
    }
  });
  const numGamesToWin = Math.ceil(maxGames / 2.0);
  const {
    player1GameWinCount,
    player2GameWinCount,
    player1PointsWon,
    player2PointsWon,
  } = gameScores(match.games);
  if (player1GameWinCount >= numGamesToWin) {
    return {
      winner: {
        id: match.player1Id,
        gamesWon: player1GameWinCount,
        gamesLost: player2GameWinCount,
        pointsWon: player1PointsWon,
        pointsLost: player2PointsWon,
      },
      loser: {
        id: match.player2Id,
        gamesWon: player2GameWinCount,
        gamesLost: player1GameWinCount,
        pointsWon: player2PointsWon,
        pointsLost: player1PointsWon,
      },
    };
  }
  if (player2GameWinCount >= numGamesToWin) {
    return {
      winner: {
        id: match.player2Id,
        gamesWon: player2GameWinCount,
        gamesLost: player1GameWinCount,
        pointsWon: player2PointsWon,
        pointsLost: player1PointsWon,
      },
      loser: {
        id: match.player1Id,
        gamesWon: player1GameWinCount,
        gamesLost: player2GameWinCount,
        pointsWon: player1PointsWon,
        pointsLost: player2PointsWon,
      },
    };
  }
  return null;
}

export function getMatchResultsByPlayerId(group) {
  const matchResultsByPlayerId = {};
  const matchesByPlayerId = {};
  group.playerIds
    .filter(playerId => playerId !== null)
    .forEach(playerId => {
      matchResultsByPlayerId[playerId] = { matchPoints: 0 };
      matchesByPlayerId[playerId] = [];
    });
  group.matches.forEach(match => {
    const defaultedPlayer = match.defaultedPlayer;
    if (defaultedPlayer) {
      // Defaulted match does not count for points.
      return;
    }
    const matchResults = getMatchResults(match, group.maxGames);
    if (matchResults) {
      matchResultsByPlayerId[matchResults.winner.id].matchPoints += 2;
      matchResultsByPlayerId[matchResults.loser.id].matchPoints += 1;
      matchesByPlayerId[matchResults.winner.id].push({
        againstPlayerId: matchResults.loser.id,
        matchesWon: 1,
        matchesLost: 0,
        gamesWon: matchResults.winner.gamesWon,
        gamesLost: matchResults.winner.gamesLost,
        pointsWon: matchResults.winner.pointsWon,
        pointsLost: matchResults.winner.pointsLost,
      });
      matchesByPlayerId[matchResults.loser.id].push({
        againstPlayerId: matchResults.winner.id,
        matchesWon: 0,
        matchesLost: 1,
        gamesWon: matchResults.loser.gamesWon,
        gamesLost: matchResults.loser.gamesLost,
        pointsWon: matchResults.loser.pointsWon,
        pointsLost: matchResults.loser.pointsLost,
      });
    }
  });

  const resultsList = Object.keys(matchResultsByPlayerId).map(playerId => ({
    id: playerId,
    ...matchResultsByPlayerId[playerId],
  }));

  // For tie breaking
  const playerIdsByMatchPoints = {};
  resultsList.forEach(resultPlayer => {
    const mp = matchResultsByPlayerId[resultPlayer.id].matchPoints;
    if (!(mp in playerIdsByMatchPoints)) {
      playerIdsByMatchPoints[mp] = [resultPlayer.id];
    } else {
      playerIdsByMatchPoints[mp].push(resultPlayer.id);
    }
  });

  // Resolve ties
  Object.keys(playerIdsByMatchPoints).forEach(mp => {
    if (playerIdsByMatchPoints[mp].length > 1 && mp > 0) {
      // Need to resolve ties
      playerIdsByMatchPoints[mp].forEach(playerId => {
        const matchesAgainstTiedPlayers = matchesByPlayerId[
          playerId
        ].filter(match =>
          playerIdsByMatchPoints[mp].some(
            curId => curId === match.againstPlayerId
          )
        );

        const matchesWon = matchesAgainstTiedPlayers.reduce(
          (acc, matchStats) => acc + matchStats.matchesWon,
          0
        );
        const matchesLost = matchesAgainstTiedPlayers.reduce(
          (acc, matchStats) => acc + matchStats.matchesLost,
          0
        );
        const gamesWon = matchesAgainstTiedPlayers.reduce(
          (acc, matchStats) => acc + matchStats.gamesWon,
          0
        );
        const gamesLost = matchesAgainstTiedPlayers.reduce(
          (acc, matchStats) => acc + matchStats.gamesLost,
          0
        );
        const pointsWon = matchesAgainstTiedPlayers.reduce(
          (acc, matchStats) => acc + matchStats.pointsWon,
          0
        );
        const pointsLost = matchesAgainstTiedPlayers.reduce(
          (acc, matchStats) => acc + matchStats.pointsLost,
          0
        );

        matchResultsByPlayerId[playerId].tiedMatchesWon = matchesWon;
        matchResultsByPlayerId[playerId].tiedMatchesLost = matchesLost;
        matchResultsByPlayerId[playerId].tiedGamesWon = gamesWon;
        matchResultsByPlayerId[playerId].tiedGamesLost = gamesLost;
        matchResultsByPlayerId[playerId].tiedPointsWon = pointsWon;
        matchResultsByPlayerId[playerId].tiedPointsLost = pointsLost;
      });
    }
  });

  resultsList.sort((a, b) => {
    if (b.matchPoints !== a.matchPoints) {
      return b.matchPoints - a.matchPoints;
    }

    const resultA = matchResultsByPlayerId[a.id];
    const resultB = matchResultsByPlayerId[b.id];

    // Compare those tied or match points
    if (resultB.tiedMatchesWon !== resultA.tiedMatchesWon) {
      return resultB.tiedMatchesWon - resultA.tiedMatchesWon;
    }

    // Compare the ratio of games won to games lost
    const gamesWinLostRatioA = resultA.tiedGamesWon / resultA.tiedGamesLost;
    const gamesWinLostRatioB = resultB.tiedGamesWon / resultB.tiedGamesLost;
    if (gamesWinLostRatioB !== gamesWinLostRatioA) {
      return gamesWinLostRatioB - gamesWinLostRatioA;
    }

    // Compare the ratio of points won to points lost
    const pointsWinLostRatioA = resultA.tiedPointsWon / resultA.tiedPointsLost;
    const pointsWinLostRatioB = resultB.tiedPointsWon / resultB.tiedPointsLost;
    if (pointsWinLostRatioB !== pointsWinLostRatioA) {
      return pointsWinLostRatioB - pointsWinLostRatioA;
    }

    return b.matchPoints - a.matchPoints;
  });
  // TODO: Need to implement repeat of USATT Rule 15.b
  resultsList.forEach((result, i) => {
    matchResultsByPlayerId[result.id].place = i + 1;
  });
  return matchResultsByPlayerId;
}
