import classNames from "classnames";
import React from "react";
import { gameScores, isMatchCompleted } from "../lib/scoresUtil";
import { Player, Match } from "../lib/matchUtil";

type SelectedScore = { gameIndex: number; playerNum: 1 | 2 };

type Props = {
  className?: string;
  match: Match;
  games: any[];
  playersById: Record<string, Player>;
  maxGames: number;
  onSelectScore: (selected: SelectedScore | null) => void;
  selectedScore: SelectedScore | null;
  invalidGames?: number[];
};

export default function MatchInputCard(props: Props) {
  const player1 = props.match.player1Id
    ? props.playersById[props.match.player1Id]
    : null;
  const player2 = props.match.player2Id
    ? props.playersById[props.match.player2Id]
    : null;

  const games = [...props.games];
  const numEmptyGamesToAdd = props.maxGames - props.games.length;
  for (let i = 0; i < numEmptyGamesToAdd; i++) {
    games.push({ score1: null, score2: null });
  }

  const { player1GameWinCount, player2GameWinCount } = gameScores(props.games);

  return (
    <div className={classNames("flex flex-row", props.className)}>
      <div className="border-2 border-black">
        <div className="flex flex-row">
          <div
            style={{ width: 220, height: 100 }}
            className={classNames(
              "flex flex-col border-2 border-black justify-center px-3",
              ((player1GameWinCount > player2GameWinCount &&
                isMatchCompleted(props.games, props.maxGames) &&
                !props.match.defaultedPlayer) ||
                props.match.defaultedPlayer === 2) &&
                "bg-green-100"
            )}
          >
            <div
              className={classNames(
                "text-xl",
                ((player1GameWinCount > player2GameWinCount &&
                  isMatchCompleted(props.games, props.maxGames) &&
                  !props.match.defaultedPlayer) ||
                  props.match.defaultedPlayer === 2) &&
                  "font-bold"
              )}
            >
              {`${player1?.firstName} ${player1?.lastName} (${player1?.usattRating})`}
              {props.match.defaultedPlayer === 1 ? (
                <span className="font-bold">[Defaulted]</span>
              ) : null}
            </div>
          </div>
          {games.map((game, index) => (
            <button
              key={index}
              onClick={e => {
                e.stopPropagation();
                props.onSelectScore({ gameIndex: index, playerNum: 1 });
              }}
              disabled={
                index >= props.games.length || !!props.match.defaultedPlayer
              }
            >
              <div
                style={{ width: 100, height: 100 }}
                className={classNames(
                  "flex border-2 border-black justify-center items-center",
                  props.selectedScore?.gameIndex === index &&
                    props.selectedScore?.playerNum === 1 &&
                    "border-blue-600 bg-gray-200",
                  (props.invalidGames || []).indexOf(index) !== -1 &&
                    "bg-red-300"
                )}
              >
                <div className="text-3xl font-medium">{game.score1}</div>
              </div>
            </button>
          ))}
        </div>
        <div className="flex flex-row">
          <div
            style={{ width: 220, height: 100 }}
            className={classNames(
              "flex flex-col border-2 border-black justify-center px-3",
              ((player2GameWinCount > player1GameWinCount &&
                isMatchCompleted(props.games, props.maxGames) &&
                !props.match.defaultedPlayer) ||
                props.match.defaultedPlayer === 1) &&
                "bg-green-100"
            )}
          >
            <div
              className={classNames(
                "text-xl",
                ((player2GameWinCount > player1GameWinCount &&
                  isMatchCompleted(props.games, props.maxGames) &&
                  !props.match.defaultedPlayer) ||
                  props.match.defaultedPlayer === 1) &&
                  "font-bold"
              )}
            >
              {`${player2?.firstName} ${player2?.lastName} (${player2?.usattRating})`}
              {props.match.defaultedPlayer === 2 ? (
                <span className="font-bold">[Defaulted]</span>
              ) : null}
            </div>
          </div>
          {games.map((game, index) => (
            <button
              key={index}
              onClick={e => {
                e.stopPropagation();
                props.onSelectScore({ gameIndex: index, playerNum: 2 });
              }}
              disabled={
                index >= props.games.length || !!props.match.defaultedPlayer
              }
            >
              <div
                style={{ width: 100, height: 100 }}
                className={classNames(
                  "flex border-2 border-black justify-center items-center",
                  props.selectedScore?.gameIndex === index &&
                    props.selectedScore?.playerNum === 2 &&
                    "border-blue-600 bg-gray-200",
                  (props.invalidGames || []).indexOf(index) !== -1 &&
                    "bg-red-300"
                )}
              >
                <div className="text-3xl font-medium">{game.score2}</div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
