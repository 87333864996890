// import StyledButton from "./StyledButton";
import * as tournamentEvents from "../lib/api/tournamentEvents";
import classNames from "classnames";
import Modal from "./Modal";
import React from "react";
import { downloadMatchCard, downloadGroupSheet } from "../lib/api/tournaments";
import { getMatchResultsByPlayerId } from "../lib/scoresUtil";

const strings = {
  bye: "Bye",
};

function describeGameScore(score1: number, score2: number) {
  if (score1 === score2) {
    return "";
  }
  const prefix = score1 > score2 ? "" : "-";
  const minScore = Math.min(score1, score2);
  return `${prefix}${minScore}`;
}

function parseShorthandScore(score: string) {
  let score1 = null;
  let score2 = null;
  if (!score) {
    return { score1, score2 };
  }

  if (score.startsWith("-")) {
    const mainScoreString = score.substring(1);
    const parsedScore = parseInt(mainScoreString);
    score1 = parsedScore;
    score2 = parsedScore > 9 ? parsedScore + 2 : 11;
  } else {
    const parsedScore = parseInt(score);
    score1 = parsedScore > 9 ? parsedScore + 2 : 11;
    score2 = parsedScore;
  }

  return { score1, score2 };
}

function getPlayerDisplayName(
  player: any,
  letter: string | undefined,
  matchStatus: "NOT_STARTED" | "IN_PROGRESS" | "COMPLETED" | "BYE"
) {
  if (matchStatus === "BYE" && !player) {
    return "Bye";
  }
  if (!player) {
    return "Empty";
  }
  if (!letter) {
    return `${player.firstName} ${player.lastName}`;
  }
  return `${letter}. ${player.firstName} ${player.lastName}`;
}

type GroupModalProps = {
  isOpen: boolean;
  group?: any;
  groupIndex?: number;
  tournamentId: string;
  eventId?: string;
  playersById?: any;
  displayFullName?: boolean;
  onRequestClose?: () => void;
};

class GroupModal extends React.Component<GroupModalProps> {
  static defaultProps = {
    displayFullName: true,
  };

  state: { loading: boolean; scoreInputs: any[] } = {
    loading: false,
    scoreInputs: [],
  };

  componentDidMount() {
    this._initializeInputs();
  }

  componentDidUpdate(prevProps: GroupModalProps) {
    if (prevProps.group !== this.props.group) {
      this._initializeInputs();
    }
  }

  componentWillUnmount() {}

  _initializeInputs = () => {
    if (!this.props.group) {
      return;
    }

    this.setState({
      scoreInputs: this.props.group.matches.map((match: any) => {
        const gameValues = [];
        for (let i = 0; i < this.props.group.maxGames; i++) {
          if (i < match.games.length) {
            const game = match.games[i];
            gameValues.push(describeGameScore(game.score1, game.score2));
          } else {
            gameValues.push("");
          }
        }
        return gameValues;
      }),
    });
  };

  _onRequestClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  _onChangeScoreInput = (
    matchIndex: number,
    gameIndex: number,
    inputValue: string
  ) => {
    this.setState((prevState: { loading: boolean; scoreInputs: any[] }) => {
      const scoreInputs = [...prevState.scoreInputs];
      scoreInputs[matchIndex][gameIndex] = inputValue;
      return {
        scoreInputs: scoreInputs,
      };
    });
  };

  _submitScoreIfNeeded = (matchIndex: number, gameIndex: number) => {
    const inputValue = this.state.scoreInputs[matchIndex][gameIndex];
    const game = this.props.group.matches[matchIndex].games[gameIndex];
    const originalValue = describeGameScore(game.score1, game.score2);
    if (inputValue === originalValue) {
      return;
    }

    // TODO: Validate inputValue

    const scores = parseShorthandScore(inputValue);
    tournamentEvents
      .setGameScore(
        this.props.tournamentId,
        this.props.group.eventId,
        this.props.group.groupIndex,
        matchIndex,
        gameIndex,
        scores.score1,
        scores.score2
      )
      .then(() => {
        // TODO: Success
      })
      .catch(err => {
        console.log(err);
      });
  };

  _onChangeDefaultedPlayer = (matchIndex: number, defaultedPlayer: any) => {
    const match = this.props.group.matches[matchIndex];
    tournamentEvents
      .editGroupMatch(
        this.props.tournamentId,
        this.props.group.eventId,
        this.props.group.groupIndex,
        matchIndex,
        match.defaultedPlayer === defaultedPlayer ? null : defaultedPlayer
      )
      .then(() => {
        // TODO: Success
      })
      .catch(err => {
        console.log(err);
      });
  };

  // _toggleDisplayFullName = () =>
  //   this.setState(prevState => ({
  //     displayFullName: !prevState.displayFullName,
  //   }));

  _getPlayerNames = (match: any) => {
    const group = this.props.group;
    if (!this.props.displayFullName && group.type === "ROUND_ROBIN") {
      return [match.player1Letter, match.player2Letter];
    }

    const playersById = this.props.playersById;
    const player1 = playersById[match.player1Id];
    const player2 = playersById[match.player2Id];
    const p1Name = getPlayerDisplayName(
      player1,
      match.player1Letter,
      match.status
    );
    const p2Name = getPlayerDisplayName(
      player2,
      match.player2Letter,
      match.status
    );
    return [p1Name, p2Name];
  };

  _renderPlayersList = () => {
    const group = this.props.group;
    if (group.type !== "ROUND_ROBIN") {
      return null;
    }

    return (
      <React.Fragment>
        <div>{`Players (${group.playerIds.length} Total)`}</div>
        {group.playerIds.map((playerId: string, i: number) => {
          const playersById = this.props.playersById;
          if (playerId === null) {
            return (
              <div key={playerId}>{`${String.fromCharCode(65 + i)}. ${
                strings.bye
              }`}</div>
            );
          }
          const p = playersById[playerId];
          return (
            <div key={playerId}>{`${String.fromCharCode(65 + i)}. ${
              p.firstName
            } ${p.lastName} (${p.usattRating})`}</div>
          );
        })}
      </React.Fragment>
    );
  };

  _renderRRResults = () => {
    const group = this.props.group;
    const matchResultsByPlayerId: {
      [id: string]: any;
    } = getMatchResultsByPlayerId(group);
    return (
      <React.Fragment>
        <div className="mt-3 font-bold">Results</div>
        <table className="mt-1">
          <thead>
            <tr>
              <td className={classNames("border border-black", "w-40")}>
                Player
              </td>
              <td className={classNames("border border-black", "w-8")}>MP</td>
              <td className={classNames("border border-black", "w-8")}>M(T)</td>
              <td className={classNames("border border-black", "w-8")}>G(T)</td>
              <td className={classNames("border border-black", "w-8")}>P(T)</td>
              <td className={classNames("border border-black", "w-8")}>
                Place
              </td>
            </tr>
          </thead>
          <tbody>
            {group.playerIds.map((playerId: string, i: number) => {
              if (playerId === null) {
                return (
                  <tr key={i}>
                    <td
                      className={"border border-black"}
                    >{`${String.fromCharCode(65 + i)}. ${strings.bye}`}</td>
                    <td colSpan={5} className={"border border-black"}></td>
                  </tr>
                );
              }
              const p = this.props.playersById[playerId];
              const results = matchResultsByPlayerId[playerId];
              return (
                <tr key={i}>
                  <td className={"border border-black"}>{`${String.fromCharCode(
                    65 + i
                  )}. ${p.firstName} ${p.lastName}`}</td>
                  <td className={"border border-black"}>
                    {results.matchPoints}
                  </td>
                  <td className={"border border-black"}>
                    {results.tiedMatchesWon}
                  </td>
                  <td className={"border border-black"}>
                    {results.tiedGamesWon !== undefined
                      ? `${results.tiedGamesWon}/${results.tiedGamesLost}`
                      : null}
                  </td>
                  <td className={"border border-black"}>
                    {results.tiedGamesWon !== undefined
                      ? `${results.tiedPointsWon}/${results.tiedPointsLost}`
                      : null}
                  </td>
                  <td className={"border border-black"}>{results.place}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  _renderContents = () => {
    const group = this.props.group;
    if (!group) {
      return null;
    }

    const nameCellClassName = this.props.displayFullName ? "w-40" : "w-8";

    return (
      <div>
        {this._renderPlayersList()}
        {/*<StyledButton onClick={this._toggleDisplayFullName}>
          {this.props.displayFullName ? "Only Show Letters" : "Show Full Name"}
        </StyledButton> REMOVE?*/}
        <div>{`Total Matches: ${group.matches.length}`}</div>
        <div
          className="cursor-pointer"
          onClick={() => downloadGroupSheet(this.props.tournamentId, group.id)}
        >
          Group Sheet PDF
        </div>
        <table style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <td className={"border border-black"}>D1</td>
              <td className={"border border-black"}>D2</td>
              <td className={"border border-black"}>Card</td>
              <td
                className={classNames("border border-black", nameCellClassName)}
              >
                P1
              </td>
              <td
                className={classNames("border border-black", nameCellClassName)}
              >
                P2
              </td>
              {Array.apply(null, Array(5)).map((_, index: number) => (
                <td
                  className={classNames("border border-black", "w-8")}
                  key={index}
                >
                  {index + 1}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {group.matches
              .concat()
              // If we sort, then we will have the wrong mIndex.
              // .sort((a, b) => {
              //   if (group.type === "SINGLE_ELIMINATION_ROUND") {
              //     return 1;
              //   }
              //   const comp1 =
              //     a.player1Letter.localeCompare(b.player1Letter) * 100;
              //   const comp2 =
              //     a.player2Letter.localeCompare(b.player2Letter) * 1;
              //   return comp1 + comp2;
              // })
              .map((match: any, mIndex: number) => {
                const [player1Name, player2Name] = this._getPlayerNames(match);
                return (
                  <tr key={mIndex}>
                    <td className={"border border-black"}>
                      <input
                        type="checkbox"
                        onChange={() =>
                          this._onChangeDefaultedPlayer(mIndex, 1)
                        }
                        checked={match.defaultedPlayer === 1}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      />
                    </td>
                    <td className={"border border-black"}>
                      <input
                        type="checkbox"
                        onChange={() =>
                          this._onChangeDefaultedPlayer(mIndex, 2)
                        }
                        checked={match.defaultedPlayer === 2}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      />
                    </td>
                    <td className={"border border-black"}>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          downloadMatchCard(
                            this.props.tournamentId,
                            match.player1Id,
                            match.player2Id
                          )
                        }
                      >
                        PDF
                      </div>
                    </td>
                    <td
                      className={classNames(
                        "border border-black",
                        nameCellClassName
                      )}
                    >
                      {player1Name}
                    </td>
                    <td
                      className={classNames(
                        "border border-black",
                        nameCellClassName
                      )}
                    >
                      {player2Name}
                    </td>
                    {match.games.map((game: any, gameIndex: number) => {
                      const gameScoreString =
                        this.state.scoreInputs &&
                        this.state.scoreInputs[mIndex] &&
                        this.state.scoreInputs[mIndex][gameIndex];
                      return (
                        <td
                          key={gameIndex}
                          className={classNames("border border-black", "w-8")}
                        >
                          <input
                            type="text"
                            style={{ width: 26 }}
                            value={gameScoreString}
                            onChange={e => {
                              const curValue = e.target.value;
                              this._onChangeScoreInput(
                                mIndex,
                                gameIndex,
                                curValue
                              );
                            }}
                            onBlur={() =>
                              this._submitScoreIfNeeded(mIndex, gameIndex)
                            }
                            disabled={match.defaultedPlayer}
                            className="py-1 px-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {group.type === "ROUND_ROBIN" ? this._renderRRResults() : null}
      </div>
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this._onRequestClose}
        contentLabel="Group Modal"
        title={this.props.group ? this.props.group.fullName : ""}
        // width={540}
        // height={400}
      >
        {this._renderContents()}
      </Modal>
    );
  }
}

export default GroupModal;
