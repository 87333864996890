import instance from "./instance";

function parseEvent(event) {
  return {
    ...event,
  };
}

export function getEvents(tournamentId) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/events`,
    })
    .then(response => response.data.map(event => parseEvent(event)));
}

export function getEvent(tournamentId, eventId) {
  return instance
    .request({
      method: "GET",
      url: `/tournaments/${tournamentId}/events/${eventId}`,
    })
    .then(response => parseEvent(response.data));
}

export function newEvent(tournamentId, event) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/events`,
      data: event,
    })
    .then(response => parseEvent(response.data));
}

export function editEvent(tournamentId, event) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/events/${event.id}`,
      data: event,
    })
    .then(response => parseEvent(response.data));
}

export function deleteEvent(tournamentId, eventId) {
  return instance
    .request({
      method: "DELETE",
      url: `/tournaments/${tournamentId}/events/${eventId}`,
    })
    .then(response => response.data.map(event => parseEvent(event)));
}

export function previewGenerateGroups(
  tournamentId,
  eventId,
  playerIds,
  numPerGroup
) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/events/${eventId}/groups/preview`,
      data: {
        playerIds,
        numPerGroup,
      },
    })
    .then(response => response.data);
}

export function generateGroups(tournamentId, eventId) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/events/${eventId}/groups`,
    })
    .then(response => parseEvent(response.data));
}

export function setGameScore(
  tournamentId,
  eventId,
  groupIndex,
  matchIndex,
  gameIndex,
  score1,
  score2
) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/events/${eventId}/score`,
      data: { groupIndex, matchIndex, gameIndex, score1, score2 },
      // {groupIndex:0,matchIndex:0,gameIndex:0,score1:7,score2:11}
    })
    .then(response => response.data);
}

export function editGroupMatch(
  tournamentId,
  eventId,
  groupIndex,
  matchIndex,
  defaultedPlayer
) {
  return instance
    .request({
      method: "POST",
      url: `/tournaments/${tournamentId}/events/${eventId}/group-match`,
      data: { groupIndex, matchIndex, defaultedPlayer },
      // {groupIndex:0,matchIndex:0,defaultedPlayer:null}
    })
    .then(response => response.data);
}
